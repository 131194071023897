.toastbar {
  position: fixed;
  display: inline-block;
  height: 0;
  overflow: visible;
  z-index: 9999;
  top: calc(#{rem-calc(60px)} + 1rem);
  width: rem-calc(350px);
  right: 1rem;
  @include media-breakpoint-up(lg) {
    top: calc(#{rem-calc(115px)} + 1rem);
  }
}

/**
 * Map to Foundation classes for coloring
 * Foundation |  Bootstrap
 * secondary  |  seondary
 * primary    |  primary
 * success    |  success
 * warning    |  warning
 * alert      |  danger
 */

$toastVariants: (
  "success": "success",
  "warning": "warning",
  "alert": "danger"
);
$toastParts: "body", "header";
@each $toastVariant, $colorName in $toastVariants {
  .toast {
    &.#{$toastVariant} {
      @if $colorName == "success" {
        border-color: $success-2;
      }
      @if $colorName == "warning" {
        border-color: $warning-2;
      }
      @if $colorName == "danger" {
        border-color: $danger-2;
        position: relative;
        padding: 0;
        margin-bottom: 0;
      }
      .toast-header {
        @if $colorName == "success" {
          border-color: $success-2;
        }
        @if $colorName == "warning" {
          border-color: $warning-2;
        }
        @if $colorName == "danger" {
          border-color: $danger-2;
        }
      }
      @each $toastPart in $toastParts {
        .toast-#{$toastPart} {
          @if $colorName == "success" {
            @include state-success-block($hover: false);
          }
          @if $colorName == "warning" {
            @include state-warning-block($hover: false);
          }
          @if $colorName == "danger" {
            @include state-danger-block($hover: false);
          }
        }
      }
    }
  }
}
