.ticket-comment {
  border-width: 2px;
  border-style: solid;
  &.is-mykpmg {
    border-color: $primary-4;
    background-color: $white;
  }
  &.is-customer {
    border-color: $secondary;
    background-color: $gray-1;
  }
}
/*
.ticket-comment {
  background: $white;
  .ticket-comment-content {
    border-width: 2px;
    border-style: solid;
    background: $white;
  }
  .ticket-comment-files {
  }
  &.is-sent,
  &.is-mykpmg {
    .ticket-comment-content {
      border-color: $kpmg-light-blue;
    }
  }
  &.is-recieved,
  &.is-customer {
    .ticket-comment-content {
      border-color: $secondary;
    }
  }
}
*/
