.custom-select {
    &:disabled {
        background: $custom-select-disabled-bg
            $custom-select-disabled-background;
    }
}

.dropdown {
    &.show {
        .dropdown-toggle {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }
    &-toggle {
        &::after {
            display: inline-block;
            vertical-align: middle;
            content: "";
            border: 0;
            width: 1rem;
            height: 1rem;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
            background-size: 75%;
            transition: all ease-in-out 0.25s;
        }
        @each $variant, $color in $variants {
            $svgColor: color-yiq($color);
            &.btn-#{$variant} {
                &::after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{encodecolor($svgColor)}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
                }
            }
            &.btn-outline-#{$variant} {
                &[aria-expanded="false"] {
                    &::after {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{encodecolor($color)}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
                    }
                }
            }
            &.btn-outline-#{$variant} {
                &[aria-expanded="true"],
                &:hover,
                &:focus {
                    &::after {
                        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{encodecolor($svgColor)}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
                    }
                }
            }
        }
    }
}

.dropdown-toggle.contained {
    &.show {
        &::after {
            transform: rotate(-180deg);
        }
    }
    &::after {
        display: inline-block;
        vertical-align: middle;
        content: "";
        border: 0;
        width: 1rem;
        height: 1rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'></path></svg>");
        background-size: 75%;
        transition: all ease-in-out 0.25s;
    }
}

.user-dropdown,
.org-dropdown {
    &-option {
        .info {
            line-height: 1.2;
        }
    }
}

.custom-select {
    &.react-select {
        background: $white;
        &.disabled {
            color: $custom-select-disabled-color;
            background-color: $custom-select-disabled-bg;
            .dropdown-indicator {
                color: $gray-3;
            }
        }
        .indicator-container {
            width: 3rem;
            &.is-clearable {
                width: 4.5rem;
            }
        }
        .dropdown-indicator {
            right: calc(1rem - 6px);
            color: $gray-4;
            padding: 8px;
            &-icon {
                transition: transform 150ms ease-in-out;
                &.closed {
                    transform: rotate(0);
                }
                &.open {
                    transform: rotate(-180deg);
                }
            }
        }
        .indicator-separator {
            height: 80%;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &.custom-select-sm {
            .indicator-container {
                width: 3rem;
                &.is-clearable {
                    width: 4.5rem;
                }
            }
        }
        &.react-select-multi {
            height: auto;
            min-height: $input-height;
            &.custom-select {
                &-sm {
                    min-height: $input-height-sm;
                }
                &-lg {
                    min-height: $input-height-lg;
                }
            }
        }
    }
}

.dropdown {
    &-image {
        background-color: $gray-2;
        &.single-value {
            width: 1.5rem;
            height: 1.5rem;
            &.single-value-sm {
                width: 1.25rem;
                height: 1.25rem;
            }
        }
        &.option {
            width: 2rem;
            height: 2rem;
            &.option-sm {
                width: 1.75rem;
                height: 1.75rem;
            }
        }
    }
}

.dropdown-menu {
    &-sm {
        padding: 0.25rem 0;
        .dropdown-item {
            font-size: 0.875rem;
            padding: 0.25rem 1em;
        }
    }
    &-width-100 {
        min-width: 100%;
    }
}

.dropdown-search-helper {
    background: transparent !important;
}
