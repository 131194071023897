.button-muted {
  font-size: 80%;
  font-weight: 400;
}

.button-clear {
  border: none;
}

.button-login-forgot-password {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}

.nav-split-dropdown {
  .btn {
    padding: 0;
    margin: 0;
  }
  .dropdown-toggle-split {
    padding-right: 0.7125rem;
    padding-left: 0.7125rem;
  }
}

.btn-cancel {
  color: $black;
  &:hover {
    text-decoration: none;
    color: $danger-3;
  }
}

.btn-remove {
  color: $danger-3;
  &:hover {
    text-decoration: none;
    color: $danger-4;
  }
}

.btn-outline-bg-white {
  background-color: white;
}

/**
 * This creates a responsively sizes button. On mobile devices it is normal sized for easier touch targeting.
 * On desktop it is a smaller button.
 */
.btn {
  &-sm-lg {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include media-breakpoint-up(lg) {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }
  }
}
