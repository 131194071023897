.inline-userorg {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    .status-icon {
        border: 2px solid;
        transform: translate(40%, -40%);
        z-index: 1;
        &.success {
            border-color: $success-1;
            background-color: $success-1;
        }
        &.warning {
            border-color: $warning-1;
            background-color: $warning-1;
        }
        &.danger {
            border-color: $danger-1;
            background-color: $danger-1;
        }
    }
}
