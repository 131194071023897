@charset 'utf-8';

@import "settings";
@import "~foundation-sites/scss/foundation";
//@import 'motion-ui';
.old {
  // Global styles

  .alert {
    position: inherit;
    padding: inherit;
    margin-bottom: inherit;
    border: inherit;
    border-radius: inherit;
  }

  @include foundation-global-styles;
  @include foundation-forms;
  @include foundation-typography;

  // Grids (choose one)
  @include foundation-xy-grid-classes;
  @include foundation-grid;
  // @include foundation-flex-grid;

  // Generic components
  @include foundation-button;
  //@include foundation-button-group;
  @include foundation-close-button;
  @include foundation-label;
  @include foundation-progress-bar;
  //@include foundation-slider; //jQuery
  @include foundation-switch;
  @include foundation-table;
  // Basic components
  @include foundation-badge;
  @include foundation-breadcrumbs;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-dropdown;
  @include foundation-pagination;
  //@include foundation-tooltip; //jQuery

  // Containers
  //@include foundation-accordion; //jQuery
  @include foundation-media-object;
  //@include foundation-orbit; //jQuery
  @include foundation-responsive-embed;
  @include foundation-tabs; //jQuery
  @include foundation-thumbnail;
  // Menu-based containers
  @include foundation-menu;
  @include foundation-menu-icon;
  //@include foundation-accordion-menu; //jQuery
  //@include foundation-drilldown-menu; //jQuery
  @include foundation-dropdown-menu;

  // Layout components
  @include foundation-off-canvas;
  @include foundation-reveal;
  @include foundation-sticky;
  @include foundation-title-bar;
  @include foundation-top-bar;

  // Helpers
  @include foundation-float-classes;
  @include foundation-flex-classes;
  @include foundation-visibility-classes;
  @include foundation-prototype-classes;

  // Motion UI
  //@include motion-ui-transitions;
  //@include motion-ui-animations;

  // mixins
  @import "mixins/container-border";
  @import "mixins/flex";
  @import "mixins/avatar";
  @import "mixins/helpers";
  @import "mixins/psuedo";
  @import "mixins/push-center";
  @import "mixins/sidelines";
  @import "mixins/zindex";
  @import "mixins/loader";
  @import "mixins/tippy-theme";

  @import "global/loader";
  @import "global/layout";
  @import "global/typography";
  @import "global/svg";
  @import "global/animation";
  @import "global/common";
  @import "global/controls/inputs";
  @import "global/controls/pagination";
  @import "global/controls/switch";
  @import "global/helpers";

  @import "components/header";
  @import "components/containers";
  @import "components/footer";
  @import "components/blocks/changelog";
  @import "components/blocks/news";
  @import "components/blocks/user";
  @import "components/blocks/contact-card";
  @import "components/chat/chat";
  @import "components/notifications/notification";
  @import "components/notifications/notification-callouts";
  @import "components/uploader";
  @import "components/navigation";
  @import "components/off-canvas";
  @import "components/modal";
  @import "components/blocks/services";
  @import "components/tables/tables";
  @import "components/tickets/tickets";

  @import "libs/chartjs";
  @import "~choices.js/src/styles/choices";
  @import "libs/choices";
  @import "libs/tippyjs";
  //@import 'libs/chartjs';

  @import "tempfixes";
}

.old.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  @media print, screen and (min-width: 40em) {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.old.header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.old.inverted {
  background-color: #005EB8;
  color: #fefefe;
}
