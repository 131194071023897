.chart-legend-color {
	width: 3em;
	display: inline-block;
	height: 1.15em;
	margin-right: 0.5em;
	line-height: 1;
	vertical-align: text-bottom;
	opacity: 0.75;
}

.chart-legend {
	margin: auto 0.5em;
	&.is-disabled {
		text-decoration: line-through;
	}
}
