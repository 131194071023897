@import "position";
@import "tooltips";
@import "backgrounds";
@import "loaders";
@import "stateblocks";
@import "cursors";

.header-card,
.content-card {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03),
        0px 1px 4px rgba(0, 0, 0, 0.05);
}

.content-card {
    border-radius: 0.375rem !important;
}

$positions: top, right, bottom, left;
@each $position in $positions {
    .grid-margin-#{$position} {
        margin-#{$position}: 1.875rem !important;
    }
}

.max-width-100 {
    max-width: 100%;
}

label,
legend {
    sup.required {
        color: $danger-3;
        font-size: 125%;
        top: -0.15em;
    }
}

.line-clamp {
    &-2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
