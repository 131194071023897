@mixin state-development-background($hover: false) {
    background-color: $gray-1;
}

@mixin state-default-background($hover: false) {
    background-color: $white;
    @include transition($transition-base);
    @if $hover != false {
        &:hover {
            background-color: $gray-1;
        }
    }
}
@mixin state-default-background-hover {
    cursor: pointer;
    &:hover {
        background-color: $gray-1;
    }
}

@mixin state-secondary-background($hover: false) {
    background-color: $gray-1;
}

@mixin state-gray-background($hover: false) {
    background-color: $gray-1;
}

@mixin state-danger-background($hover: false) {
    background-color: $danger-1;
    @include transition($transition-base);
    @if $hover != false {
        &.has-hover {
            cursor: pointer;
            &:hover {
                background-color: $danger-2;
            }
        }
    }
}
@mixin state-danger-background-hover {
    cursor: pointer;
    &:hover {
        background-color: $danger-2;
    }
}

@mixin state-success-background($hover: false) {
    background-color: $success-1;
    @include transition($transition-base);
    @if $hover != false {
        cursor: pointer;
        &:hover {
            background-color: $success-2;
        }
    }
}
@mixin state-success-background-hover {
    cursor: pointer;
    &:hover {
        background-color: $success-2;
    }
}

@mixin state-warning-background($hover: false) {
    background-color: $warning-1;
    @include transition($transition-base);
    @if $hover != false {
        cursor: pointer;
        &:hover {
            background-color: $warning-2;
        }
    }
}
@mixin state-warning-background-hover {
    cursor: pointer;
    &:hover {
        background-color: $warning-2;
    }
}
