.is-reveal-open {
  width: 100%;
  height: 100%;
  body {
    width: 100%;
    height: 100%;
  }
}

.is-reveal-open {
  .cell-block-container.cell-block-y,
  .content {
    @include breakpoint(small down) {
      overflow: visible !important;
    }
  }
}

.reveal-overlay {
  &__open {
    display: block;
  }
}

.reveal {
  &__open {
    display: block;
  }
}

.avatar-upload-wrapper {
  border: 1px dashed;
  background-color: $light-gray;
  background-image: url("/../../../img/profile-large.png");
  background-size: cover;
  &.company {
    background-image: url("/../../../img/org-profile-large.png");
  }
  &.person {
    background-image: url("/../../../img/profile-large.png");
  }
  canvas {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .loading-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
  }
}

.avatar-upload {
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.avatar-upload-text {
  display: block;
  transform: translateY(-50%);
  width: 90%;
  margin: 50% auto 0;
}

.modal-grid-y {
  height: calc(100% + 8rem);
  @include breakpoint(large up) {
    height: calc(100% - 150px);
  }
  .grid-frame {
    height: calc(100% - 3rem);
  }
}
