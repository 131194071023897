.filepond--wrapper {
  .filepond--root {
    margin-bottom: 0.5em;
  }
}

.filepond--root .filepond--drop-label {
  background-image: repeating-linear-gradient(
    135deg,
    $light,
    $light 1.5px,
    $complementary-gray 1.5px,
    $complementary-gray 12px
  );
  left: 0;
  right: 0;
  border-radius: 5px;
}
