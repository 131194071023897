.loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 9999;
  &.cover-white {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.8) 35%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
}

.loading-holder {
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-right: 1rem;
  position: relative;
  vertical-align: top;
  &.cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .loading {
      width: 5em;
      height: 5em;
      &:before {
        font-size: 2em;
      }
    }
    &.cover-white {
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.8) 35%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
  }
}
