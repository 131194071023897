@import "headers";
@import "text-colors";

$fontsizes: (
    smaller: 0.75,
    small: 0.875,
    normal: 1,
    large: 1.1875
);
@each $name, $size in $fontsizes {
    .font-size-#{$name} {
        font-size: #{$size}rem;
    }
}

label {
    font-size: 0.875rem;
    &.custom-control-label,
    &.form-check-label {
        font-size: 1rem;
    }
}

.font-family-kpmg {
    letter-spacing: 1px;
    font-family: "KPMG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

$line-heights: (
    "1": 1,
    "12": 1.2,
);
@each $name, $line-height in $line-heights {
    .text-line-height-#{$name} {
        line-height: $line-height;
    }
}

.text-missinginfo {
    color: $secondary;
}
