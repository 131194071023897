.notification-callout-holder {
	position: fixed;
    display: inline-block;
	height: 0;
	overflow: visible;
	z-index: 9999;
	top: rem-calc(56px);
	width: calc(100% - 1rem);
	margin: 0 0.5rem;
	@include breakpoint(large up) {
		width: rem-calc(400px);
		left: 1rem;
		top: rem-calc(126px);
		margin: 0;
	}
	.notification-callout {
		margin-bottom: 0.5rem;
		@include breakpoint(large up) {
			margin-bottom: 1rem;
		}
	}
}
