.faux {
  &-table {
    border: 1px solid $lighter-gray;
    .is-sortable {
      padding: 0.5rem 0;
      &:hover {
        background-color: $light-gray;
      }
    }
    .is-sorted {
      background-color: $light-gray;
      &-asc {
        #icon-sort-down {
          display: none;
        }
      }
      &-desc {
        #icon-sort-up {
          display: none;
        }
      }
    }
    &.table-header {
      background: $lighter-gray;
      .faux-table-column {
        padding: 0.5rem;
      }
    }
    &.striped {
      .faux-table-row {
        padding: 0.5rem;
        &:nth-child(2n) {
          border-bottom: 0;
          background-color: $lighter-gray;
        }
      }
    }
  }
}

.chart-data {
  &-table {
    &-more {
      max-height: rem-calc(500px);
      transition: max-height ease-in-out 0.25s;
      &.is-collapsed {
        max-height: 0;
        transition: max-height ease-in-out 0.25s;
      }
    }
    .faux-table-row {
      .cost {
        color: rgba($kpmg-light-purple, 1);
      }
      .income {
        color: rgba($kpmg-green, 1);
      }
    }
  }
}

.customer {
  &-table {
    &-row {
      padding: 0.5rem 0;
      &:nth-child(2n) {
        border-bottom: 0;
        background-color: $lighter-gray;
      }
    }
  }
  &-button {
    &-action {
      padding: 0.3em;
    }
  }
}
.cell.customers-table-column {
  @include breakpoint(large up) {
    padding: 0 0.5rem;
  }
  &-image {
    @include breakpoint(large up) {
      width: 5%;
    }
  }
  &-name {
    @include breakpoint(large up) {
      width: 23%;
    }
  }
  &-orgnr {
    @include breakpoint(large up) {
      width: 15%;
    }
  }
  &-custnr {
    @include breakpoint(large up) {
      width: 14%;
    }
  }
  &-custmgr {
    @include breakpoint(large up) {
      width: 19%;
    }
  }
  &-financial {
    @include breakpoint(large up) {
      width: 9%;
    }
  }
  &-actions {
    @include breakpoint(large up) {
      width: 15%;
    }
  }
}

.user {
  &-table {
    &-row {
      padding: 0.5rem 0;
      &:nth-child(2n) {
        border-bottom: 0;
        background-color: $lighter-gray;
      }
    }
  }
  &-button {
    &-action {
      padding: 0.3em;
    }
  }
}
.cell.users-table-column {
  @include breakpoint(large up) {
    padding: 0 0.5rem;
  }
  &-image {
    @include breakpoint(large up) {
      width: 5%;
    }
  }
  &-name {
    @include breakpoint(large up) {
      width: 20%;
    }
  }
  &-email {
    @include breakpoint(large up) {
      width: 18%;
    }
  }
  &-organization {
    @include breakpoint(large up) {
      width: 12%;
    }
  }
  &-location {
    @include breakpoint(large up) {
      width: 13%;
    }
  }
  &-invited {
    @include breakpoint(large up) {
      width: 11%;
    }
  }
  &-login {
    @include breakpoint(large up) {
      width: 11%;
    }
  }
  &-actions {
    @include breakpoint(large up) {
      width: 10%;
    }
  }
}

.external {
  .cell.users-table-column {
    @include breakpoint(large up) {
      padding: 0 0.5rem;
    }
    &-image {
      @include breakpoint(large up) {
        width: 3%;
      }
    }
    &-name {
      @include breakpoint(large up) {
        width: 16%;
      }
    }
    &-personal-number {
      @include breakpoint(large up) {
        width: 15%;
      }
    }
    &-email {
      @include breakpoint(large up) {
        width: 18%;
      }
    }
    &-organization {
      @include breakpoint(large up) {
        width: 10%;
      }
    }
    &-location {
      @include breakpoint(large up) {
        width: 11%;
      }
    }
    &-invited {
      @include breakpoint(large up) {
        width: 8%;
      }
    }
    &-login {
      @include breakpoint(large up) {
        width: 9%;
      }
    }
    &-actions {
      @include breakpoint(large up) {
        width: 10%;
      }
    }
  }
}

.table-services-history {
  .ordernr {
    @include breakpoint(large up) {
      width: 7%;
    }
  }
  .status {
    @include breakpoint(large up) {
      width: 13%;
    }
  }
  .created {
    @include breakpoint(large up) {
      width: 10%;
    }
  }
  .company {
    @include breakpoint(large up) {
      width: 18%;
    }
  }
  .service {
    @include breakpoint(large up) {
      width: 35%;
    }
  }
  .createdby {
    @include breakpoint(large up) {
      width: 15%;
    }
  }
  .completed {
    @include breakpoint(large up) {
      width: 10%;
    }
  }
  .action {
    @include breakpoint(large up) {
      width: 10%;
    }
  }
  &.clientlink {
    .service {
      @include breakpoint(large up) {
        width: 45%;
      }
    }
  }
}

.cell.sie-file-table-column {
  @include breakpoint(large up) {
    padding: 0 0.5rem;
  }
  &-customer {
    @include breakpoint(large up) {
      width: 20%;
    }
  }
  &-period {
    @include breakpoint(large up) {
      width: 12%;
    }
  }
  &-uploadedby {
    @include breakpoint(large up) {
      width: 18%;
    }
  }
  &-uploaded {
    @include breakpoint(large up) {
      width: 11%;
    }
  }
  &-recievedby {
    @include breakpoint(large up) {
      width: 18%;
    }
  }
  &-recieved {
    @include breakpoint(large up) {
      width: 11%;
    }
  }
  &-actions {
    @include breakpoint(large up) {
      width: 10%;
    }
  }
}

.cell.file-table-column {
  @include breakpoint(large up) {
    padding: 0 0.5rem;
  }
  &-select {
    @include breakpoint(large up) {
      width: 2%;
    }
  }
  &-customer {
    @include breakpoint(large up) {
      width: 16%;
    }
  }
  &-file {
    @include breakpoint(large up) {
      width: 18%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 26%;
      }
    }
  }
  &-uploadedby {
    @include breakpoint(large up) {
      width: 15%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 19%;
      }
    }
  }
  &-uploaded {
    @include breakpoint(large up) {
      width: 11%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 11%;
      }
    }
  }
  &-recievedby {
    @include breakpoint(large up) {
      width: 15%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 19%;
      }
    }
  }
  &-recieved {
    @include breakpoint(large up) {
      width: 11%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 11%;
      }
    }
  }
  &-actions {
    @include breakpoint(large up) {
      width: 12%;
    }
    &.is-customer-view {
      @include breakpoint(large up) {
        width: 12%;
      }
    }
  }
}

button.table-action-button {
  padding: 0.85em 0.6em;
}
