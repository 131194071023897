/**
 * Global
 */
.tippy-tooltip {
	border-radius: $prototype-border-radius;
	font-size: 1rem;
	padding: 1rem;
}

.tippy-tooltip {
	background-clip: padding-box;
	box-shadow: $prototype-box-shadow;
	.tippy-arrow,
	.tippy-roundarrow {
		&:after,
		&:before {
			content: "";
			position: absolute;
			z-index: -1
		}
	}
	.tippy-roundarrow {
		&:after {
			background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0IiB2aWV3Qm94PSIwIDAgMTggNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA3czIuMDIxLS4wMTUgNS4yNTMtNC4yMThDNi41ODQgMS4wNTEgNy43OTcuMDA3IDkgMGMxLjIwMy0uMDA3IDIuNDE2IDEuMDM1IDMuNzYxIDIuNzgyQzE2LjAxMiA3LjAwNSAxOCA3IDE4IDd6IiBmaWxsPSIjMzMzIiBmaWxsLW9wYWNpdHk9Ii4yMzUiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
			background-size: 18px 7px;
			width: 18px;
			height: 7px;
			left: 0;
			top: 0;
		}
	}
	&[x-placement^=top] {
		.tippy-roundarrow {
			&:after {
				top: 1px;
				transform: rotate(180deg);
			}
		}
		.tippy-arrow {
			&:after {
				top: -7px;
			}
			&:before {
				bottom: -1px;
			}
		}
	}
	&[x-placement^=bottom] {
		.tippy-roundarrow {
			&:after {
				top: -1px;
			}
		}
		.tippy-arrow {
			&:after {
				bottom: -7px
			}
			&:before {
				bottom: -6px
			}
		}
	}
	&[x-placement^=left] {
		.tippy-roundarrow {
			&:after {
				left: 1px;
				top: 0;
				transform: rotate(90deg)
			}
		}
		.tippy-arrow {
			&:after {
				left: -7px;
			}
			&:before {
				left: -6px;
			}
		}
	}
	&[x-placement^=right] {
		.tippy-roundarrow {
			&:after {
				left: -1px;
				top: 0;
				transform: rotate(-90deg);
			}
		}
		.tippy-arrow {
			&:after {
				right: -7px;
			}
			&:before {
				right: -6px;
			}
		}
	}
	&[x-placement^=bottom],
	&[x-placement^=top] {
		.tippy-arrow,
		.tippy-roundarrow {
			transform: translateX(-1px);
		}
		.tippy-arrow {
			&:before,
			&:after {
				left: -7px;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
			}
		}
	}

	&[x-placement^=left],
	&[x-placement^=right] {
		.tippy-arrow,
		.tippy-roundarrow {
			transform: translateY(-1px)
		}
		.tippy-arrow {
			&:before,
			&:after {
				top: -7px;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
			}
		}
	}
}

@include tippy-theme( 'kpmg', 'kpmg-medium-blue', true );
//@include tippy-theme( 'alert', 'alert', true );
@include tippy-theme( 'light-border', white, false, 20%) ;
