.modal-title-small {
    font-size: 125%;
    color: $secondary;
}

$widths: 90vw, 80vw;
@each $width in $widths {
    .modal-#{$width} {
        @include media-breakpoint-up(md) {
            width: $width;
            max-width: none;
        }
    }
}

.modal-secondary-above {
    z-index: 1070;
    &-backdrop {
        z-index: 1060;
    }
}

.modal-header {
    border-bottom: 0;
    padding: 1.5rem 1.5rem 1rem;
    .modal-title {
        &.h4 {
            font-size: $h1-font-size;
        }
    }
}

.modal-footer {
    padding: 1rem 1rem;
}
