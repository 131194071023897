.progress {
	&.warning {
		background-color: $warning-3;
		.progress-bar {
			background-color: $warning-4;
		}
	}
	&.success {
		background-color: $success-3;
		.progress-bar {
			background-color: $success-4;
		}
	}
	&.danger {
		background-color: $danger-3;
		.progress-bar {
			background-color: $danger-4;
		}
	}
}
