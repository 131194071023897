@mixin tippy-theme($name, $color, $linkcolor: false, $darken: 10% ) {
	.tippy-tooltip {
		&.#{$name}-theme {
			background-color: get-color($color);
			border: 1px solid darken(get-color($color), $darken);
			color: #{color-pick-contrast(get-color($color))};
			@if $linkcolor == true {
				a {
					color: #{color-pick-contrast(get-color($color))};
				}
			}
			.tippy-backdrop {
				background-color: get-color($color);
			}
			.tippy-roundarrow {
				fill: get-color($color);
				&:after {
					fill: darken(get-color($color), $darken);
				}
			}
			&[x-placement^=top] {
				.tippy-arrow {
					border-top-color: get-color($color);
					&:after {
						border-top: 7px solid get-color($color);
					}
					&:before {
						border-top: 7px solid darken(get-color($color), $darken);
					}
				}
			}
			&[x-placement^=bottom] {
				.tippy-arrow {
					border-bottom-color: get-color($color);
					&:after {
						border-bottom: 7px solid get-color($color);
					}
					&:before {
						border-bottom: 7px solid darken(get-color($color), $darken);
					}
				}
			}
			&[x-placement^=left] {
				.tippy-arrow {
					border-left-color: get-color($color);
					&:after {
						border-left: 7px solid get-color($color);
					}
					&:before {
						border-left: 7px solid darken(get-color($color), $darken);
					}
				}
			}
			&[x-placement^=right] {
				.tippy-arrow {
					border-right-color: get-color($color);
					&:after {
						border-right: 7px solid get-color($color);
					}
					&:before {
						border-right: 7px solid darken(get-color($color), $darken);
					}
				}
			}
		}
	}
}

@function color-pick-contrast($base, $colors: ($white, $black), $tolerance: $global-color-pick-contrast-tolerance) {
	$contrast: color-contrast($base, nth($colors, 1));
	$best: nth($colors, 1);
	$color: $black;

	@for $i from 2 through length($colors) {
		$current-contrast: color-contrast($base, nth($colors, $i));
		@if ($current-contrast - $contrast > $tolerance) {
			$contrast: color-contrast($base, nth($colors, $i));
			$best: nth($colors, $i);
		}
	}

	@if ($contrast-warnings and $contrast < 3) {
		@warn "Contrast ratio of #{$best} on #{$base} is pretty bad, just #{$contrast}";
	}

	@if($best==$white) {
		$color: $white;
	} @else {
		$color: $black;
	}
	@return $color;
}
