@for $i from 0 through 3 {
  $margin: ($i);

  .mb#{$i} {
    margin-bottom: $margin+rem !important;
  }
}

.icon-left {
  margin: 0 0.6em 0 0;
  &.small {
    margin: 0 0.3em 0 0;
  }
}
.icon-right {
  margin: 0 0 0 0.6em;
  &.small {
    margin: 0 0 0 0.3em;
  }
}
