.menu {
  flex-wrap: nowrap;
  &.header-menu > li {
    > a,
    > button {
      padding: 0;
      display: flex;
      &:hover {
        color: $white;
        background: $kpmg-blue;
      }
      &.empty {
        &:hover {
          color: $white;
          background: transparent;
        }
      }
      &.inverted {
        &:hover {
          color: $kpmg-blue !important;
          background: $white;
        }
      }
    }
  }
  &__item {
    //flex-basis: calc( 100% / 7);
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    //flex-grow: 0;
    &.dropdown-parent {
      > a,
      > button {
        &:after {
          @include css-triangle(6px, $white, down);
          margin-top: rem-calc(-3px);
          position: absolute;
          top: auto;
          margin: auto;
          left: calc(50% - 6px);
          right: auto;
          bottom: 0.25rem;
          transition-duration: 0.2s;
        }
        &.inverted {
          &.active,
          &:hover {
            &:after {
              @include css-triangle(6px, $kpmg-blue, down);
            }
          }
        }
        &.spin {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
    a {
      &.dropdown-holder {
        padding: 0;
      }
    }
  }
  li > a.header-menu-item svg,
  li > a.header-menu-item img,
  li > button.header-menu-item svg,
  li > button.header-menu-item img{
    display: block;
    height: 46px;
  }
  img.bordered {
    border-color: $white;
  }
  svg {
    padding-bottom: 0.25rem;
  }
  a.active,
  button.active {
    background: $kpmg-blue;
    &.inverted {
      background: $white;
      color: $kpmg-blue !important;
    }
  }
}

.menu {
  .is-submenu-item {
    svg {
      padding-bottom: 0;
    }
  }
}

.profile-menu,
.profile-submenu,
.notification-menu {
	background: $kpmg-blue;
  text-align: left;
  border: 0;
  &.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: $white transparent transparent;
  }
}

.notification-menu {
  background: white;
  color: $kpmg-blue;
  //border: 1px solid $kpmg-medium-blue;
  border-top: 0;
  margin-right: -1px;
  //min-width: 400px;
  width: 400px;
}

li.is-dropdown-submenu-parent {
  &.spin {
    > a {
      &::after {
        transform:rotate(180deg);
        transition-duration: 0.2s;
      }
    }
  }
}

.profile-submenu {
  padding: 0 1rem;
}

.profile-menu {
	z-index: 999;
	width: 300%;
}

/*#menu-profile img {
  width: 50px;
}*/


.mobile-menu {
  .profile-image {
    height: 1em;
    width: 1em;
  }
  .title {
    > a {
      background: $kpmg-light-blue;
    }
  }
  .submenu {
    a {
      padding-left: 2em;
    }
  }
}

.menu-submenu-parent {
	&:after {
		position: absolute;
		bottom: 0;
		@include css-triangle(10px, $kpmg-light-blue, up);
	}
}

a.button-submenu,
button.button-submenu {
  &.active {
    background: $kpmg-blue;
    //text-decoration: underline;
  }
}

.submenu-customer-actions {
  @include breakpoint(medium down) {
    position: absolute;
    top: 100%;
  }
}

.button-customer-submenu {
  @include breakpoint(medium down) {
    width: 100%;
    text-align: left;
  }
}

button.button-customer-submenu {
  color: $white !important;
  svg {
    transition: all 0.25s ease-in-out;
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.header-submenu {
  position: sticky;
  z-index: 99;
  top: $navbar-height !important;
  @include breakpoint(large up) {
    top: $navbar-height-large !important;
  }
}
