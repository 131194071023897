.box {
	height: 0;
	padding-top: 100%;
	&__content {
		@include position(absolute, 0, 0, 0, 0);
		@include border-radius(5px);
	}
}

.circle {
	height: 0;
	padding-top: 100%;
	&__content {
		@include position(absolute, 0, 0, 0, 0);
		@include border-radius(100%);
	}
}

.reveal {
	user-select: text;
}
