.logo {
  max-width: 250px;
}

.logo-text {
  & > svg {
    width: 35.6%;
  }
  & > h1 {
    font-size: 10vw;
    white-space: nowrap;
    line-height: 1;
    margin-top: -3%;
    margin-bottom: 0.8rem;
  }
}

.logo-wider {
  max-width: 300px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
  & > svg {
    max-height: 40px;
    width: 30%;
  }
  & > h1 {
    display: inline-block;
    line-height: 0.9;
    font-size: 7vw;
    margin: 0;
    color: #fff;
  }
}

@media (min-width: 640px) {
  .logo-text > h1 {
    font-size: 4.2rem;
  }
  .logo-wider > h1 {
    font-size: 3.2rem;
  }
}

div#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content.container-fluid {
  flex: 1;
}
