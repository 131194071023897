@import "buttons";
@import "dropdowns";
@import "signatories";
@import "switches";
@import "draggable";
@import "datepicker";
@import "filepond";

.input-clear {
    &.is-visible {
        opacity: 1;
    }
    opacity: 0;
    transition: all ease-in-out 250ms;
}
