footer {
	width: 100%;
	max-width: 100vw;
	display: block;
	margin-left: 50%;
	transform: translateX(-50%);
	&.footer-gray {
		background: $light-gray;
	}
}
