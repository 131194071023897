.svg-icon {
	height: 1em;
	width: 1em;
	&-tiny {
		svg {
			width: 0.5em;
			height: 0.5em;
		}
	}
	&-smaller {
		svg {
			width: 0.7em;
			height: 0.7em;
		}
	}
	&-small {
		svg {
			width: 0.85em;
			height: 0.85em;
		}
	}
	&-medium {
		svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}
	&-large {
		svg {
			height: 2rem;
			width: 2rem;
		}
	}
	&-button {
		svg {
			height: 0.9rem;
			//margin-left: 0.15rem;
		}
		&-medium {
			svg {
				//margin-top: -0.25rem;
				//margin-left: 0.25rem;
			}
		}
	}
	&-background {
		&-white {
			svg {
				background: $white;
			}
		}
	}
	&-rounded {
		svg {
			border-radius: 50%;
		}
	}
}

.input-large {
	.svg-icon,
	&.svg-icon {
		svg {
			height: 1.25rem;
		}
	}
}

.text {
	&-smallest {
		.svg-icon {
			svg {
				height: 0.625rem;
			}
		}
	}
	&-smaller {
		.svg-icon {
			svg {
				height: 0.75rem;
			}
		}
	}
	&-small {
		.svg-icon {
			svg {
				height: 0.875rem;
			}
		}
	}
	&-normal {
		.svg-icon {
			svg {
				height: 1rem;
			}
		}
	}
}

.svg-vertical-align {
	&-top {
		svg {
			vertical-align: top;
		}
	}
	&-middle {
		svg {
			vertical-align: middle;
		}
	}
	&-bottom {
		svg {
			vertical-align: bottom;
		}
	}
	&-text-top {
		svg {
			vertical-align: text-top;
		}
	}
	&-vertical-50 {
		svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.mobile-menu {
	li {
		a {
			svg {
				height: 1em;
				width: 1em;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

/*button,
.button {
	&:not(.button-no-svg-margin) {
		svg {
			margin: 0 0.5rem;
		}
	}
}*/

button.filepond--file-action-button {
	svg {
		margin: 0;
	}
}

.svg-margin {
	&-auto {
		svg {
			margin: auto;
		}
	}
	&-left {
		&-half {
			svg {
				margin-left: 0.5rem;
			}
		}
	}
	&-right {
		&-half {
			svg {
				margin-right: 0.5rem;
			}
		}
	}
}

.svg-icon-moved {
	svg {
		width: 100%;
		height: auto;
	}
	@keyframes kf_el_WDJSPol7eN_an_boeFSCeca {
		0% {
			opacity: 1;
		}
		66.67% {
			opacity: 1;
		}
		67.22% {
			opacity: 0;
		}
		75% {
			opacity: 0;
		}
		75.56% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes kf_el_yHWSCheG4w_an_d0pqyHrIT {
		0% {
			opacity: 1;
		}
		66.67% {
			opacity: 1;
		}
		67.22% {
			opacity: 0;
		}
		75% {
			opacity: 0;
		}
		75.56% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes kf_el_DXJGfip4-f_an_ULnLyZVAi {
		16.11% {
			opacity: 1;
		}
		28.33% {
			opacity: 0;
		}
		66.67% {
			opacity: 0;
		}
		75% {
			opacity: 0;
		}
		75.56% {
			opacity: 1;
		}
		0% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes kf_el_yHWSCheG4w_an_xFsBJIYNl {
		50% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(0px, 0px);
		}
		66.67% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(500px, 0px);
		}
		75% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(-500px, 0px);
		}
		91.67% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(0px, 0px);
		}
		0% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(0px, 0px);
		}
		100% {
			transform: translate(237px, 221.24000549316406px) translate(-237px, -221.24000549316406px) translate(0px, 0px);
		}
	}
	@keyframes kf_el_WDJSPol7eN_an_RQh7f93UY {
		50% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(0px, 0px);
		}
		66.67% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(500px, 0px);
		}
		75% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(-500px, 0px);
		}
		91.67% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(0px, 0px);
		}
		0% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(0px, 0px);
		}
		100% {
			transform: translate(215.58999633789062px, 2.8299999237060547px) translate(-215.58999633789062px, -2.8299999237060547px) translate(0px, 0px);
		}
	}
	@keyframes kf_el_yHWSCheG4w_an_PJwMRAgo2 {
		28.33% {
			transform: translate(237px, 221.24000549316406px) rotate(0deg) translate(-237px, -221.24000549316406px);
		}

		50% {
			transform: translate(237px, 221.24000549316406px) rotate(105deg) translate(-237px, -221.24000549316406px);
		}
		66.67% {
			transform: translate(237px, 221.24000549316406px) rotate(105deg) translate(-237px, -221.24000549316406px);
		}
		75% {
			transform: translate(237px, 221.24000549316406px) rotate(0deg) translate(-237px, -221.24000549316406px);
		}
		0% {
			transform: translate(237px, 221.24000549316406px) rotate(0deg) translate(-237px, -221.24000549316406px);
		}
		100% {
			transform: translate(237px, 221.24000549316406px) rotate(0deg) translate(-237px, -221.24000549316406px);
		}
	}
	@keyframes kf_el_DXJGfip4-f_an_yB3Tw3d1c {
		5% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(0px, 0px);
		}
		28.33% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(250px, -50px);
		}
		66.67% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(250px, -50px);
		}
		75% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(-500px, 0px);
		}
		91.67% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(0px, 0px);
		}
		0% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(0px, 0px);
		}
		100% {
			transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(0px, 0px);
		}
	}

	#el_4ZIhPJfcQ * {
		animation-duration: 6s;
		animation-iteration-count: infinite;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_DXJGfip4-f_an_yB3Tw3d1c {
		animation-fill-mode: forwards;
		transform: translate(8.733948707580566px, 68.62395477294922px) translate(-8.733948707580566px, -68.62395477294922px) translate(0px, 0px);
		animation-name: kf_el_DXJGfip4-f_an_yB3Tw3d1c;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_yHWSCheG4w_an_PJwMRAgo2 {
		animation-fill-mode: forwards;
		transform: translate(237px, 221.24000549316406px) rotate(0deg) translate(-237px, -221.24000549316406px);
		animation-name: kf_el_yHWSCheG4w_an_PJwMRAgo2;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_WDJSPol7eN_an_RQh7f93UY {
		animation-fill-mode: forwards;
		animation-name: kf_el_WDJSPol7eN_an_RQh7f93UY;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_yHWSCheG4w_an_xFsBJIYNl {
		animation-fill-mode: forwards;
		animation-name: kf_el_yHWSCheG4w_an_xFsBJIYNl;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_DXJGfip4-f {
		animation-fill-mode: forwards;
		animation-name: kf_el_DXJGfip4-f_an_ULnLyZVAi;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_yHWSCheG4w {
		animation-fill-mode: forwards;
		animation-name: kf_el_yHWSCheG4w_an_d0pqyHrIT;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}

	#el_WDJSPol7eN {
		animation-fill-mode: forwards;
		animation-name: kf_el_WDJSPol7eN_an_boeFSCeca;
		animation-timing-function: cubic-bezier(0, 0, 1, 1);
	}
}