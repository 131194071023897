.pagination {
	.current {
		a {
			color: $white;
			&:hover {
				background: transparent;
				color: $white;
			}
		}
	}
}
