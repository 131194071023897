.#{$choices-selector}__list--dropdown {
    z-index: 3;
}

.#{$choices-selector}[data-type*="select-multiple"] input[type="text"].#{$choices-selector}__input {
	width: auto;
    display: inline-block;
    border: 0;
    box-shadow: none;
    margin: 0;
}

.choices.is-disabled[data-type*="select-multiple"] input[type="text"].choices__input {
    display: none;
}

.choices__select-wide {}

@media (min-width: 640px) {
    .#{$choices-selector}__list--dropdown {
        .#{$choices-selector}__item--selectable,
        .#{$choices-selector}__item--disabled {
            padding-right: 100px;
        }
    }
}

.choices__inner {
    .choices__item-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.#{$choices-selector}__list--multiple .#{$choices-selector}__item {
    border-radius: $prototype-border-radius;
}

.#{$choices-selector}__list--multiple .#{$choices-selector}__item.#{$choices-selector}__item-alert {
    background-color: get-color(alert);
    border: 1px solid get-color(alert);
}

.#{$choices-selector}[data-type*="select-multiple"] .#{$choices-selector}__button,
.#{$choices-selector}[data-type*="text"] .#{$choices-selector}__button {
    border-left: 1px solid rgba($white, 0.75);
}

input[type="text"].#{$choices-selector}__input {
    display: inline-block;
    border-radius: $prototype-border-radius;
    border: 0;
    box-shadow: none;
    padding: 0;
    margin: 0 0.5rem;
}

.#{$choices-selector}-full-width-items {
    .#{$choices-selector}__item.#{$choices-selector}-item-full-width {
        display: block;
        position: relative;
        button.#{$choices-selector}__button {
            right: 0.5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.#{$choices-selector} {
	&__item {
        &-kpmg-template {
            &-image {
                max-width: 50px;
            }
            &-info {
            }
        }
		&-image {
			max-width: 50px;
        }
        &-info-text {
            color: $dark-gray;
        }
    }
}

.#{$choices-selector}__list--multiple {
    .#{$choices-selector} {
        &__item {
            &-image {
                max-width: 50px;
            }
            &-info-text {
                color: $white;
            }
        }
    }
}


.choices__select-wide {
    .choices__list {
        &.choices__list--dropdown {
            width: 200%;
            &.right {
                right: 0;
            }
        }
    }
}
