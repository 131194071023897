.nav-mobile,
.nav-mobile.navbar-nav {
  color: theme-color("white");
  a,
  a.nav-link,
  a.nav-link.active,
  a.nav-link:hover {
    color: theme-color("white");
  }
}

.nav-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.nav-mobile-shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.65) !important;
}

.nav-mobile-button-close {
  top: 0;
  right: 0;
}

.bm-menu,
.bm-item-list {
  width: 100%;
}
