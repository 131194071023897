.ticket-grid {
	@include breakpoint(xlarge up) {
		display: grid;
		grid-template-columns: [ticket-description] 5fr [ticket-comments] 7fr;
		grid-template-rows: [ticket-header] rem-calc(150px) [ticket-content] 1fr; //calc(100vh - 5.625rem - 1rem - (0.9375rem * 2) - #{rem-calc(140px)});
		grid-column-gap: calc(0.9375rem * 2);
		grid-row-gap: calc(0.9375rem * 3);
		//height: calc(100vh - 5.625rem - 2rem);
	}
	.ticket-header {
		margin-bottom: 1rem;
		@include breakpoint(xlarge up) {
			grid-column: 1/-1;
			grid-row: ticket-header;
			position: relative;
			position: sticky;
			top: 5.625rem;
			background: $white;
			z-index: 1;
			margin-bottom: 0;
		}
	}
	.ticket-description {
		margin-bottom: 1rem;
		@include breakpoint(xlarge up) {
			grid-column: ticket-description;
			grid-row: ticket-content;
			max-height: calc(100vh - 5.625rem - 1rem - (0.9375rem * 4) - #{rem-calc(140px)});
			position: sticky;
			top: 285px;
			margin-bottom: 0;
		}
	}
	.ticket-comments {
		@include breakpoint(xlarge up) {
			grid-column: ticket-comments;
			grid-row: ticket-content;
			margin-top: rem-calc(-30px);
		}
	}
	&-new {
		grid-template-columns: [ticket-description] 1fr;
		grid-template-rows: [ticket-header] rem-calc(150px) [ticket-content] 1fr; //calc(100vh - 5.625rem - 1rem - (0.9375rem * 2) - #{rem-calc(140px)});
		grid-column-gap: 0;
	}
}

.ticket-new-comment {
	background:$white;
}

.tickets-grid {
	.ticket {
		&:nth-child(odd) {
			> .grid-x {
				@include breakpoint(large up) {
					margin-right: -0.5rem;
				}
			}
		}
		&:nth-child(even) {
			> .grid-x {
				@include breakpoint(large up) {
					margin-left: -0.5rem;
				}
			}
		}
		.ticket-desc {
			line-height: 1.6;
			height: calc( 4em * 1.6 );
			text-overflow: ellipsis;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
		}
		&-state {
			@include breakpoint(medium down) {
				padding-right: 1rem;
			}
		}
	}
}

.ticket-open {
	.ticket-comments {
		.grid-y.large-grid-frame {
			@include breakpoint(large up) {
				height: calc(100vh - 200px);
			}
		}
	}
}

.ticket-comments {
	.grid-y.large-grid-frame {
		@include breakpoint(large up) {
			$scroller_height: rem-calc(250px);
			height: calc(100vh - #{$scroller_height});
		}
	}
}

.ticket-state {
	padding-right: 0.25rem;
	//background:$white;
	/*&.skew {
		background:$white;
		&:before {
			padding-right: 0.25rem;
			background: $white;
			transform: skewX(-15deg);
			content: "";
			display: block;
			width: 2rem;
			height: 100%;
			left: -1rem;
			top: 0;
			position: absolute;
		}
	}*/
}

.ticket-header-status {
	.ticket-status {
		width: 2rem;
		height: 2rem;
		margin-right: 0.25rem;
		&:last-child {
			margin-right: 0;
		}
	}
}

.ticket-comment {
	background-color: $light-gray;
	border-radius: $prototype-border-radius;
}

.ticket-small-my {
	> * {
		pointer-events: none;
	}
}

.ticket-overview-id {
	height: 3rem;
}


/*.ticket-header {
	.ticket-header-id {
		width: 70%;
	}
	.ticket-header-status {
		width: 30%;
	}
}*/

.ticket-comment {
	background: $white;
	.ticket-comment-content {
		border-width: 2px;
		border-style: solid;
		background: $white;
	}
	.ticket-comment-files {

	}
	&.is-sent,
	&.is-mykpmg {
		.ticket-comment-content {
			border-color: $kpmg-light-blue;
		}
	}
	&.is-recieved,
	&.is-customer {
		.ticket-comment-content {
			border-color: $medium-gray;
		}
	}
}

$breakpoint_sizes: medium, large;
$header-sizes: h1, h2, h3, h4, h5, h6;
.ticket-comment {
	@each $header-size in $header-sizes {
		#{$header-size} {
			color: black;
			font-family: $body-font-family;
			font-weight: normal;
			font-size: rem-calc(map-deep-get($header-styles, small, #{$header-size}, font-size) * 0.8 * 1px);
		}
	}
	@each $breakpoint_size in $breakpoint_sizes {
		@include breakpoint(#{$breakpoint_size} up) {
			@each $header-size in $header-sizes {
				#{$header-size} {
					font-size: rem-calc(map-deep-get($header-styles, #{$breakpoint_size}, #{$header-size}, font-size) * 0.8 * 1px);
				}
			}
		}
	}

}

.ticket-comments {

	.tabs {
		height: rem-calc(30px);
		//margin-bottom: rem-calc(-1px);
		margin-left: rem-calc(3px);
	}
	.tabs-content {
		/*height: calc(100% - #{rem-calc(34px)});
		overflow: scroll;*/
	}

	.tabs-title {
		margin-top: rem-calc(1px);
		border: 1px solid #e6e6e6;
		border-bottom: 0;
		> a {
			padding: 0.5rem 1.5rem;
			background: $light-gray;
			&:focus,
			&[aria-selected="true"] {
				background-color: $white;
			}
		}
		&.is-active {
			font-weight: bold;
			> a {

			}
		}
	}
}
