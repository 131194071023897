.loading-holder {
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: 1rem;
	position: relative;
	vertical-align: top;
	&.cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		z-index: 9999;
		.loading {
			width: 5em;
    		height: 5em;
			&:before {
				font-size: 2em;
			}
		}
		&.cover-white {
			background: radial-gradient(circle, rgba(255,255,255,.9) 0%, rgba(255,255,255,0.8) 35%, rgba(255,255,255,0.5) 100%);
		}
	}
}
.loading {
	width: 1em;
	height: 1em;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	right: 0;
	margin: auto;
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		font-size: 0.5em;
		text-indent: -9999em;
		width: .5em;
		height: .5em;
		border: none;
		border-radius: 1em;
		animation: loader-animation-black 1.1s infinite ease;
		transform: translateZ(0) translateY(-50%);
	}
	&.large {
		width: 3em;
		height: 3em;
		&:before {
			font-size: 1em;
		}
	}
}
.button {
	.loading {
		&:before {
			animation: loader-animation-white 1.1s infinite ease;
		}
	}
	&.clear,
	&.hollow {
		.loading {
			&:before {
				animation: loader-animation-text-color-primary 1.1s infinite ease;
			}
		}
	}
}

@each $key,$val in $foundation-palette {
	.#{$key} {
		.loading {
			&:before {
				animation-name: #{color-pick-contrast-loader-keyframe(get-color($key))};
			}
		}
	}
}

@include loader-keyframe(black, $black);
@include loader-keyframe(white, $white);

@each $key,$val in $foundation-palette {
	.text-color-#{$key} > {
		.loading-holder .loading {
			&:before {
				animation-name: loader-animation-text-color-#{$key};
			}
		}
		@include loader-keyframe(text-color-#{$key}, get-color($key));
	}
}



@each $key,$val in $foundation-palette {
	.button {
		&.clear,
		&.hollow {
			&.#{$key} > {
				.loading-holder .loading {
					&:before {
						animation-name: loader-animation-text-color-#{$key};
					}
				}
				@include loader-keyframe(text-color-#{$key}, get-color($key));
			}
		}
	}
}
