.signatory {
  background-color: $gray-1;
  &.is-draggable {
    border-style: dashed;
    border-width: 2px;
    border-color: $gray-3;
  }
  &.is-dragging {
    //transform: scale(1.05);
    border-style: solid;
    border-color: $primary-3;
    background-color: $primary-2;
  }
  .status-icon {
    border: 2px solid;
    transform: translate(40%, -40%);
    z-index: 1;
    &.success {
      border-color: $success-1;
      background-color: $success-1;
    }
    &.warning {
      border-color: $warning-1;
      background-color: $warning-1;
    }
    &.danger {
      border-color: $danger-1;
      background-color: $danger-1;
    }
  }
}

.signatory-group {
  background-color: $gray-2;
  min-height: 3rem;
  .signatory {
    &:only-child,
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  &-new {
    min-height: 10rem;
    width: 100%;
    background-color: $gray-1;
    transition: $transition-base;
    &.isDraggingOver {
      background-color: $gray-2;
    }
  }
}
