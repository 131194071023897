.login-position-middle {
  padding-top: 50px;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    top: 0;
    bottom: 0;
    left: 30px;
    right: 30px;
    position: absolute;
  }
}

.login-container {
  width: 95%;
  @include media-breakpoint-up(lg) {
    width: auto;
  }
}
