.inverted {
	background-color: $kpmg-medium-blue;
	color: $white;
	label {
		color: $white;
	}
	a {
		color: $white;
	}
	.reveal {
		a {
			color: get-color(primary);
		}
	}
}

.grid-container {
	width: 100%;
}
