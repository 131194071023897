body:before {
	display: none;
	content: "small";
	@include breakpoint(medium up) {
    content: "medium";
	}
	@include breakpoint(large up) {
		content: "large";
	}
}

.in-development {
	min-height: 250px;
	position: relative;

	&:before {
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		content: "";
		background-color: rgba($white, 0.9);
		filter: blur(10px);
	}

	&:after {
		content: "In development";
		color: get-color(alert);
		font-weight: bold;
		filter: blur(0);
		position: absolute;
		top: 50%;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		display: block;
		text-align: center;
		//margin-top: 50%;
		transform: translateY(-50%);
	}
}

.pointer-events {
	&__none {
		pointer-events: none;
		&-below {
			> * {
				pointer-events: none;
			}
		}
	}

	&__auto {
		pointer-events: auto;
	}

	&__initial {
		pointer-events: initial;
	}
}

$extend_padding_helpers: 4,
5;

@each $size in $extend_padding_helpers {
	.padding-top-#{$size} {
		padding-top: #{$size}rem !important;
	}
	.padding-bottom-#{$size} {
		padding-bottom: #{$size}rem !important;
	}
}

$size_units: 0,
1,
2,
3,
4,
5;

@each $size in $size_units {
	.padding-top-large-#{$size} {
		@include breakpoint(large up) {
			padding-top: #{$size}rem !important;
		}
	}

	.padding-right-large-#{$size} {
		@include breakpoint(large up) {
			padding-right: #{$size}rem !important;
		}
	}

	.padding-bottom-large-#{$size} {
		@include breakpoint(large up) {
			padding-bottom: #{$size}rem !important;
		}
	}

	.padding-left-large-#{$size} {
		@include breakpoint(large up) {
			padding-left: #{$size}rem !important;
		}
	}
	.margin-top-large-#{$size} {
		@include breakpoint(large up) {
			margin-top: #{$size}rem !important;
		}
	}

	.margin-right-large-#{$size} {
		@include breakpoint(large up) {
			margin-right: #{$size}rem !important;
		}
	}

	.margin-bottom-large-#{$size} {
		@include breakpoint(large up) {
			margin-bottom: #{$size}rem !important;
		}
	}

	.margin-left-large-#{$size} {
		@include breakpoint(large up) {
			margin-left: #{$size}rem !important;
		}
	}
	.margin-top-negative-#{$size} {
		margin-top: -#{$size}rem !important;
	}

	.margin-right-negative-#{$size} {
		margin-right: -#{$size}rem !important;
	}

	.margin-bottom-negative-#{$size} {
		margin-bottom: -#{$size}rem !important;
	}

	.margin-left-negative-#{$size} {
		margin-left: -#{$size}rem !important;
	}
}


.padding {
	&-medium-down {
		&-half {
			@include breakpoint(medium down) {
				padding: 0.5rem !important;
			}
		}
		&-1 {
			@include breakpoint(medium down) {
				padding: 1rem !important;
			}
		}
		&-2 {
			@include breakpoint(medium down) {
				padding: 2rem !important;
			}
		}
	}
	&-half {
		padding: 0.5rem !important;
	}
	&-left {
		&-half {
			padding-left: 0.5rem !important;
		}
		&-medium-down-half {
			@include breakpoint(medium down) {
				padding-left: 0.5rem !important;
			}
		}
		&-quarter {
			padding-left: 0.25rem !important;
		}
	}
	&-right {
		&-half {
			padding-right: 0.5rem !important;
		}
		&-medium-down-half {
			@include breakpoint(medium down) {
				padding-right: 0.5rem !important;
			}
		}
		&-quarter {
			padding-right: 0.25rem !important;
		}
	}
	&-top {
		&-half {
			padding-top: 0.5rem !important;
		}
		&-medium-down-1 {
			@include breakpoint(medium down) {
				padding-top: 1rem !important;
			}
		}
		&-medium-down-half {
			@include breakpoint(medium down) {
				padding-top: 0.5rem !important;
			}
		}
		&-quarter {
			padding-top: 0.25rem !important;
		}
	}
	&-bottom {
		&-half {
			padding-bottom: 0.5rem !important;
		}
		&-medium-down-1 {
			@include breakpoint(medium down) {
				padding-bottom: 1rem !important;
			}
		}
		&-medium-down-half {
			@include breakpoint(medium down) {
				padding-bottom: 0.5rem !important;
			}
		}
		&-medium-down-8 {
			@include breakpoint(medium down) {
				padding-bottom: 8rem !important;
			}
		}
		&-quarter {
			padding-bottom: 0.25rem !important;
		}
	}
}

.margin {
	&-auto {
		margin: auto;
	}
	&-half {
		margin: 0.5rem;
	}
	&-left {
		&-half {
			margin-left: 0.5rem !important;
		}
		&-quarter {
			margin-left: 0.25rem !important;
		}
		&-auto {
			margin-left: auto !important;
		}
		&-negative-half {
			margin-left: -0.5rem !important;
		}
	}
	&-right {
		&-half {
			margin-right: 0.5rem !important;
		}
		&-quarter {
			margin-right: 0.25rem !important;
		}
		&-auto {
			margin-right: auto !important;
		}
		&-negative-half {
			margin-right: -0.5rem !important;
		}
	}
	&-top {
		&-half {
			margin-top: 0.5rem !important;
		}
		&-quarter {
			margin-top: 0.25rem !important;
		}
		&-negative-quarter {
			margin-top: -0.25rem !important;
		}
		&-negative-half {
			margin-top: -0.5rem !important;
		}
	}
	&-bottom {
		&-half {
			margin-bottom: 0.5rem !important;
		}
		&-quarter {
			margin-bottom: 0.25rem !important;
		}
		&-negative-half {
			margin-bottom: -0.5rem;
		}
	}
}

.radius {
	&-top-left {
		border-top-left-radius: $prototype-border-radius !important;
	}
	&-top-right {
		border-top-right-radius: $prototype-border-radius !important;
	}
	&-bottom-right {
		border-bottom-right-radius: $prototype-border-radius !important;
	}
	&-bottom-left {
		border-bottom-left-radius: $prototype-border-radius !important;
	}
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.animation-spinner {
	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.border {
	&-color {
		&-dark-gray {
			border-color: $dark-gray;
		}
	}
	&-width {
		&-fat {
			border-width: rem-calc(3px);
		}
	}
}

.cursor {
	&-help {
		cursor: help;
	}
	&-pointer {
		cursor: pointer;
	}
}

.positioning {
	&-top {
		top: 0;
	}
	&-bottom {
		bottom: 0;
	}
	&-left {
		left: 0;
	}
	&-right {
		right: 0;
	}
}

.display-block {
	&-medium-down {
		@include breakpoint(medium down) {
			display: block;
		}
	}
}

.z-index {
	&-99 {
		z-index: 99;
	}
}


.vertical-align {
	&-top {
		vertical-align: top;
	}
	&-middle {
		vertical-align: middle !important;
	}
	&-bottom {
		vertical-align: bottom;
	}
	&-text-top {
		vertical-align: text-top;
	}
	&-baseline {
		vertical-align: baseline;
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	select,
	textarea,
	:not(.input-large) > input {
		@include breakpoint(medium down) {
			font-size: 16px;
			&:focus {
				font-size: 16px;
			}
		}
	}
}


@for $i from 1 through 20 {
	.zindex-#{$i} {
		z-index: $i;
	}
}

.background-color {
	&-lighter-gray {
		background-color: $lighter-gray;
	}
	&-light-gray {
		background-color: $light-gray;
	}
	&-medium-gray {
		background-color: $medium-gray;
	}
}


@each $key,$val in $foundation-palette {
	.background-color-#{$key} {
		background-color: get-color($key);
	}
}


.overflow-visible {
	&-large {
		@include breakpoint(large up) {
			overflow: visible;
		}
	}
}

.vertical-50 {
	top: 50%;
	transform: translateY(-50%);
}


.scrollbar-custom {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	&::-webkit-scrollbar-track {
		background: $lighter-gray;
		background-clip: content-box;
		//width: 18px;
		border-radius: 9px;
		border: solid 6px transparent;
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:hover {
		background-color: $medium-gray;
		border-radius: 9px;
		border: solid 6px transparent;
		background-clip: padding-box;
		min-height: 100px;
		&:hover {
			background-color: $dark-gray;
		}
	}
	&::-webkit-scrollbar-corner,
	&::-webkit-scrollbar-thumb {
		cursor: pointer;
	}
	&::-webkit-scrollbar {
		height: 18px;
		width: 18px;
	}
	/*&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
	  width: 26px;
	  border-radius: 13px;
	  background-clip: padding-box;
	  border: 10px solid transparent;
	  min-height: 100px;
	}

	&::-webkit-scrollbar-thumb {
	  box-shadow: inset 0 0 0 10px;
	}*/
}



.border {
	&-top {
		&-left-radius {
			border-top-left-radius: $prototype-border-radius;
		}
		&-right-radius {
			border-top-right-radius: $prototype-border-radius;
		}
	}
	&-bottom {
		&-left-radius {
			border-bottom-left-radius: $prototype-border-radius;
		}
		&-right-radius {
			border-bottom-right-radius: $prototype-border-radius;
		}
	}
}

.translateY {
	&-negative-100 {
		transform: translateY(-100%);
	}
}


.joyride-margin,
.tippy-helper-margin {
	margin: auto 0.2em;
}

.content-loading {
	min-height: calc(100vh - #{$navbar-height} - 300px );
	@include breakpoint(large up) {
		min-height: calc(100vh - #{$navbar-height-large} - 250px);
	}
}

.columns {
	&-2 {
		columns: 2;
	}
}

.flex {
	&-wrap {
		flex-wrap: wrap;
		&-reverse {
			flex-wrap: wrap-reverse;
		}
	}
	&-nowrap {
		flex-wrap: nowrap;
	}
}

.icon {
	&.left {
		margin: 0 0.3em 0 0;
	}
	&.right {
		margin: 0 0 0 0.3em;
	}
}


.label {
	&.small {
		font-size: 0.625rem !important;
	}
}

.text {
	&-nowrap {
		white-space: nowrap;
	}
}

.button-responsive {
	font-size: 0.75rem;
	@include breakpoint(xlarge up) {
		font-size: 0.9rem;
	}
}
