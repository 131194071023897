.reports-navigation {
    font-weight: 500;
}

.report-summary-card-title {
    color: #8a8a8a;
    font-size: smaller;
}

.report-summary-card-content {
    font-weight: bold;
}

.report-summary-card-active {
    border: 1px solid #005eb8
}

.report-summary-card-icon {
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.report-summary-card-icon-circle {
    border-radius: 50%;
    border-width: 2;
    //padding: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border-style: solid;
    justify-content: center;
    align-items: center;
    display: flex;
}

$skeleton-background-color: rgb(206, 206, 206);

.report-skeleton-heading {
    background-color: $skeleton-background-color;
    width: 100%;
    height: 2rem;
    margin: 0.5rem;
}

.report-skeleton-number {
    background-color: $skeleton-background-color;
    width: 20%;
    height: 2rem;
    margin: 0.5rem;
}

.report-skeleton-badge-icon {
    background-color: $skeleton-background-color;
    width: 6rem;
    height: 1rem;
    margin: 0.5rem;
}

.report-skeleton-icon-circle {
    border-radius: 50%;
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    background-color: $skeleton-background-color;
}

.report-skeleton-wrapper {
    position: relative;
    overflow: hidden;
    background: #fcfafa;
}

/* animation effects */
.shimmer-wrapper {
    position: absolute;
    /* parent must be relative position & overflow hidden */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}

.shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.2);
}

@keyframes loading {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(-60%);
    }
    100% {
        transform: translateX(150%);
    }
}

.report-summary-tag-active-engagement {
    color: #770e00;
    background-color: #feecea;
    font-size: 10px;
    padding: 5px 0px 5px 0px;
    width: 100px;
    margin-right: 10px;
}

.report-summary-tag-historical-engagement {
    color: #0a0a0a;
    background-color: #f5f5f5;
    font-size: 10px;
    padding: 5px 0px 5px 0px;
    width: 100px;
    margin-right: 10px;
}

.report-skeleton-badge-tag {
    background-color: $skeleton-background-color;
    width: 4.6rem;
    height: 1rem;
    margin: 0.2rem;
}
.report-filter-accordian {
    .report-filter-card {
        background-color: #FFFFFF;
        padding-bottom: 8px;
    }
    .card {
        @media only screen and (min-width: 1000px) {
            float: left;
        }
    }
    .chevron {
        float: right;
        margin-left: "2vw"
    }

    .report-filter-card-body {
        width: 12vw;
        @media screen and (max-width: 750px) {
            width: 20vw;
        }
        @media screen and (max-width: 450px) {
            width: 40vw;
        }
        transition: width 0.3s;
    }
}

.card-accordian-closed {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.search-filter-accordian-closed {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.report-filter-accordian-closed {
    margin-left: -30px;
}


.report-filter-accordian-buttons {
    display: flex;
}

.report-collapsible-row{
    display: block;

    .row {
        border-bottom: 1px solid #dee2e6;
    }

    .collapsible-row-column-title{
        margin-left: 0.2 rem;
    }
    .start-column{
        margin-left: 0.9rem;
        color: #8a8a8a;
    }
    .second-column{
        color: #636363;
    }
}


.report-container-position {
    position: relative;
    z-index: 1000;
}

.report-filter-container-position {
    position: absolute;
    z-index: 2000;
}

.report-filter-input {
    width:20vw;
    @media screen and (max-width: 1000px) {
        width: 30vw;
    }
    @media screen and (max-width: 750px) {
        width: 50vw;
    }
}

.new-report-button-col {
    @media screen and (max-width: 750px) {
        margin-top: 5rem;
        float: left;
        display: flex;
    }
}

.report-landing-page-message-container {
    z-index: 2000000;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    width: 23rem;

    .report-landing-page-message-card {
        border: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    @media screen and (max-height: 1200px) {
        bottom: 0.5rem;
        right: 1rem;
    }

    @media screen and (max-width: 732px) {
        bottom: 0.5rem;
        right: 1rem;
    }

    @media screen and (max-width: 600px) {
        bottom: 0.5rem;
        right: 0.5rem;
    }

    .report-landing-page-message-button {
        border-radius: 100%;
        height: 5rem;
        width: 5rem;
        background-color: #3780C6;
    }

    .report-landing-page-message-header {
        background-color: #0091DA;
        color: white
    }

    .report-landing-images {
        height: 3rem;
        border-radius: 50%;
    }

    .report-landing-sub-image {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
    }

    .report-landing-support-time {
        color:#D3D3D3
    }

    .report-landing-header-title{
        font-size: larger;
        font-weight: 600;
    }
    .report-landing-header-images{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .report-landing-header-first-image{
        margin-left: -2.5rem;
    }

    .report-landing-header-second-image{
        position: absolute;
        margin-left: 2.5rem;
    }

    .report-landing-comment-question{
        padding: 10px
    }

    .report-landing-submit {
        background-color: white;
        border: none;
        .report-landing-submit-icon{
            color:#3780C6
        }
    }
    .report-landing-submit:active {
        background-color: white;
        border: none;
        .report-landing-submit-icon{
            color:#3780C6
        }
    }

    .report-landing-support-spinner {
        margin-right: 1.1rem;
        margin-bottom: 0.7rem;
    }

    .report-landing-support-spinner-wrapper{
        margin-top: 1.57rem;
    }

    .report-support-request-success {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }

    .report-success-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
