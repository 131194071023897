@mixin state-default-block($hover: false) {
    @include state-default-background($hover: $hover);
}
@mixin state-secondary-block($hover: false) {
    @include state-secondary-background($hover: $hover);
}
@mixin state-gray-block($hover: false) {
    @include state-gray-background($hover: $hover);
}
@mixin state-development-block($hover: false) {
    color: $gray-3;
    @include state-development-background($hover: $hover);
}
@mixin state-danger-block($hover: false) {
    color: $danger-4;
    @include state-danger-background($hover: $hover);
}
@mixin state-success-block($hover: false) {
    color: $success-4;
    @include state-success-background($hover: $hover);
}
@mixin state-warning-block($hover: false) {
    color: $warning-4;
    @include state-warning-background($hover: $hover);
}
