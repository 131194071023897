.profile {
	&-information {
		@include breakpoint(medium down) {
			width: 100%;
			padding: 0;
			margin: 0;
			display: block;
			.choices {
				margin: 0;
			}
		}
	}
}

.my-profile {
	.input-group {
		.invisible {
			@include breakpoint(medium down) {
				display: none;
			}
		}
	}
}

.ticket-recipient {
	&-select {
		&-email,
		&-organization {
			color: $dark-gray;
		}
	}
}

.team-member {
	&:hover {
		.team-member-remove {
			display: block;
		}
	}
	.team-member-remove {
		display: none;
		@include breakpoint(large down) {
			display: block;
		}
	}
}