.notification-menu {
	height: rem-calc(500px);
}

.notification-list {
	.notification {
		> * {
			pointer-events: none;
		}
		&-wrapper {
			&:first-child {
				margin-top: 1rem;
			}
			&:last-child {
				margin-bottom: 1rem;
			}
			&:hover {
				background: $lightest-gray;
			}
		}
	}
}