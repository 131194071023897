.switch {
	&.smaller {
		height: 1.25rem;
		.switch-paddle {
			width: 3rem;
			height: 1.25rem;
			font-size: 0.75rem;
			&:after {
				top: 0.25rem;
				left: 0.25rem;
				width: 0.75rem;
				height: 0.75rem;
			}
		}
		input:checked ~ .switch-paddle::after {
			left: 2rem;
		}
	}
}
