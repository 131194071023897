.custom-switch {
    .custom-control-label {
        &::before {
            background-color: $gray-2;
            border-color: $gray-2;
        }
        &::after {
            top: calc(0rem + 4px);
            left: calc(-3.125rem + 4px);
            box-shadow: $box-shadow-sm;
            background-color: $white;
        }
    }
    .custom-control-input {
        &[disabled],
        &:disabled {
            ~ .custom-control-label {
                &::before {
                    background-color: $gray-1;
                }
                &::after {
                    background-color: rgba($gray-3, 0.5);
                }
            }
            &:checked {
                ~ .custom-control-label {
                    &::before {
                        border-color: rgba($primary, 0.85);
                        background-color: rgba($primary, 0.85);
                    }
                    &::after {
                        background-color: rgba($white, 0.85);
                    }
                }
            }
        }
    }
}
