@each $stateblock in $states {
    .state-#{$stateblock}-block {
        @if $stateblock == "default" {
            @include state-default-block($hover: false);
        }
        @if $stateblock == "development" {
            @include state-development-block($hover: false);
        }
        @if $stateblock == "success" {
            @include state-success-block($hover: false);
            hr {
                border-top-color: $success-3;
            }
            .text-missinginfo {
                color: $success-4;
            }
        }
        @if $stateblock == "warning" {
            @include state-warning-block($hover: false);
            hr {
                border-top-color: $warning-3;
            }
            .text-missinginfo {
                color: $warning-4;
            }
        }
        @if $stateblock == "danger" {
            @include state-danger-block($hover: false);
            hr {
                border-top-color: $danger-3;
            }
            .text-missinginfo {
                color: $danger-4;
            }
        }
        @if $stateblock == "secondary" {
            @include state-secondary-block($hover: false);
            hr {
                border-top-color: $gray-3;
            }
        }
        @if $stateblock == "gray" {
            @include state-gray-block($hover: false);
        }
        &.has-hover {
            @if $stateblock == "default" {
                @include state-default-background-hover;
            }
            @if $stateblock == "secondary" {
                @include state-default-background-hover;
            }
            @if $stateblock == "success" {
                @include state-success-background-hover;
            }
            @if $stateblock == "warning" {
                @include state-warning-background-hover;
            }
            @if $stateblock == "danger" {
                @include state-danger-background-hover;
            }
        }
    }
}
