/**
 * Legacy: clean up
 */
.table {
    thead {
        background: transparent;
    }
    thead,
    tbody,
    tfoot {
        border: 0;
    }
    /*tbody tr:nth-child(even) {
        background-color: transparent;
    }*/
    th {
        border-top: 0;
    }


    .company-name-truncate {
        width: 70%;
    }
}

.generic-table {
    &.with-pointer {
        .generic-table-row {
            cursor: pointer;
        }
    }
    .generic-table-row {
        border-bottom: 1px solid $gray-300;
        &:hover {
            background-color: $gray-300;
        }
    }
    .line-after {
        position: relative;
        &::after {
            content: "";
            display: block;
            height: 15px;
            border-right: 1px solid $gray-300;
            width: 1px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

/**
 * New keep around
 */
.table-wrapper {
    overflow: auto;
}
.table {
    font-size: 14px;
    .th,
    .td {
        padding-top: $table-cell-padding;
        padding-bottom: $table-cell-padding;
        vertical-align: top;
        border-bottom: $table-border-width solid $table-border-color;
        &.border-sm-none {
            border-bottom-width: 0;
            @include media-breakpoint-up(lg) {
                border-bottom: $table-border-width solid $table-border-color;
            }
        }
        .required {
            color: $danger-3;
        }
        &.line-after {
            position: relative;
            &::after {
                content: "";
                display: block;
                height: 15px;
                border-right: 1px solid $gray-300;
                width: 1px;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &-sm-none {
                &::after {
                    width: 0;
                    border-right-width: 0;
                }
                @include media-breakpoint-up(lg) {
                    &::after {
                        width: 1px;
                        border-right-width: 1px;
                    }
                }
            }
        }
    }

    .thead .th {
        vertical-align: bottom;
        font-weight: bold;
        border-top: 0;
        border-bottom: $table-border-width solid $table-border-color;
    }
    $breakpoints: map-merge($grid-breakpoints, $grid-breakpoints-extended);
    @each $breakpoint, $width in $breakpoints {
        &.min-#{$breakpoint} {
            min-width: $width;
        }
    }
    .table-col-truncate-width {
        width: 0;
    }
}

.table {
    @each $state, $stateColors in $statesWithColors {
        .state-#{$state}-block {
            transition: padding 0s ease-in-out;
            @each $color, $colorValue in $stateColors {
                @if $color == "bgNormal" {
                    .td {
                        border-bottom-color: map-deep-get(
                            $statesWithColors,
                            $state,
                            "border"
                        );
                    }
                    .th,
                    .td {
                        &.line-after {
                            position: relative;
                            &::after {
                                border-right-color: map-deep-get(
                                    $statesWithColors,
                                    $state,
                                    "border"
                                );
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "ticket-table";
@import "signing-table";
@import "_reports_table";
