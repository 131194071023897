.radio__container,
.checkbox__container {
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	user-select: none;
	&:hover {
		input {
			~ .checkbox,
			~ .radio {
				background-color: $medium-gray;
			}
		}
	}
	input {
		opacity: 0;
		cursor: pointer;
		position: absolute;
		&:hover {
			~ .checkbox,
			~ .radio {
				background-color: $medium-gray;
			}
		}
		&:focus {
			~ .checkbox,
			~ .radio {
				outline: $kpmg-light-blue auto 2px;
			}
		}
		&:checked {
			~ .checkbox,
			~ .radio {
				background-color: $kpmg-light-blue;
				&:after {
					display: block;
				}
			}
			&:hover {
				~ .checkbox,
				~ .radio {
					background-color: darken($kpmg-light-blue, 5%);
					cursor: not-allowed;
				}
			}
		}
		&:disabled {
			cursor: not-allowed;
			&:hover {
				~ .checkbox,
				~ .radio {
					background-color: $light-gray;
					cursor: not-allowed;
				}
			}
			&:checked {
				~ .checkbox,
				~ .radio {
					background-color: $kpmg-light-blue;
					&:after {
						display: block;
					}
				}
			}
			&:focus {
				~ .checkbox,
				~ .radio {
					outline: $kpmg-light-blue auto 2px;
				}
			}
		}
	}
	@each $key,$val in $foundation-palette{
		&.#{$key} {
			input {
				&:focus {
					~ .checkbox,
					~ .radio {
						outline: get-color($key) auto 2px;
					}
				}
				&:checked {
					~ .checkbox,
					~ .radio {
						background-color: get-color($key);
					}
					&:hover {
						~ .checkbox,
						~ .radio {
							background-color: darken(get-color($key), 5%);
						}
					}
				}
				&:disabled {
					&:checked {
						~ .checkbox,
						~ .radio {
							background-color: get-color($key);
						}
					}
					&:focus {
						~ .checkbox,
						~ .radio {
							outline: get-color($key) auto 2px;
						}
					}
				}
			}
		}
	}
	.checkbox,
	.radio {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.5rem;
		width: 1.5rem;
		background-color: $light-gray;
		&:after {
			content: "";
			position: absolute;
			display: none;
			top: 50%;
			left: 50%;
		}
	}
	.checkbox {
		&:after {
			width: 5px;
			height: 10px;
			border: solid $white;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg) translateX(-50%) translateX(-3px) translateY(-50%) translateY(3px);
		}
	}
	.radio {
		border-radius: 50%;
		&:after {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			transform: translateX(-50%) translateY(-50%);
			background: $white;
		}
	}
	&.large {
		padding-left: 43.75px;
		font-size: 1.09375rem;
		.checkbox,
		.radio {
			height: 2rem;
			width: 2rem;
		}
		.checkbox {
			&:after {
				width: 6.25px;
				height: 12.5px;
				border-width: 0 3px 3px 0;
				transform: rotate(45deg) translateX(-50%) translateX(-3.75px) translateY(-50%) translateY(3.75px);
			}
		}
		.radio {
			&:after {
				width: 12px;
				height: 12px;
			}
		}
	}
	&.small {
		padding-left: 23.33px;
		font-size: 1.09375rem;
		.checkbox,
		.radio {
			height: 1rem;
			width: 1rem;
		}
		.checkbox {
			&:after {
				width: 4px;
				height: 7px;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg) translateX(-50%) translateX(-2px) translateY(-50%) translateY(2px);
			}
		}
		.radio {
			&:after {
				width: 8px;
				height: 8px;
			}
		}
	}
	&.no-label {
		.checkbox {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.login-input {
	.input-group-label {
		background: transparent;
		border: 0;
		color: $black;
	}
}

.login-input {
	.input-group,
	.input-group input,
	input {
		&[type='text'],
		&[type='password'],
		&[type='month'],
		&[type='week'],
		&[type='email'],
		&[type='number'],
		&[type='search'],
		&[type='tel'],
		&[type='url'],
		&[type='color'],
		textarea {
			border: 0;
			border-bottom: 1px solid $dark-gray;
			background: transparent;
			color: $black;
			padding-bottom: 0;
			box-shadow: none;
			&:focus {
				border-color: $black;
				color: $black;
			}
		}
		textarea.input-group-field {
			white-space: inherit;
		}
		span.invisible {
			@include breakpoint(medium down) {
				display: none;
			}
		}
	}
}

.inverted {
	.input-group-label {
		background: transparent;
		border: 0;
		color: $white;
	}
	.login-input {
		.input-group,
		.input-group input,
		input {
			&[type='text'],
			&[type='password'],
			&[type='month'],
			&[type='week'],
			&[type='email'],
			&[type='number'],
			&[type='search'],
			&[type='tel'],
			&[type='url'],
			&[type='color'] {
				border: 0;
				border-bottom: 1px solid $medium-gray;
				background: transparent;
				color: $white;
				padding-bottom: 0;
				box-shadow: none;
				&:focus {
					border-color: $white;
					color: $white;
				}
			}
		}
	}
	.login-input {
		.input-group input,
		input {
			&[type='text'],
			&[type='password'],
			&[type='month'],
			&[type='week'],
			&[type='email'],
			&[type='number'],
			&[type='search'],
			&[type='tel'],
			&[type='url'],
			&[type='color'] {
				border-color: $medium-gray;
				&:focus {
					border-color: $white;
				}
			}
		}
		.checkbox__container {
			input[type="checkbox"] {
				&:hover ~ .checkbox {
					background-color: $medium-gray;
				}
				&:checked ~ .checkbox {
					background-color: $white;
				}
				&:focus ~ .checkbox {
					outline: $kpmg-light-blue auto 2px;
				}
			}
			.checkbox {
				&:after {
					border-color: $kpmg-light-blue;
				}
			}
		}
	}
}

input[type="radio"] {
	&:disabled {
		background-color: $dark-gray;
		border-radius: 50%;
	}
}

.input {
	&-large {
		font-size: 1.25rem;
		input {
			font-size: 1.25rem;
		}
		.button {
			font-size: 1.25rem;
		}
	}
}

legend {
    display: block;
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.8;
}

input[type="radio"] {
	+ label {
		color: $black;
		font-size: 1rem;
	}
}

label,
legend {
	color: $dark-gray;
	sup {
		color: get-color(alert);
		display: inline-block;
		font-size: 100%;
	}
	&.disabled:hover {
		cursor: not-allowed !important;
	}
}

label,
legend {
	sup.required {
		top: 0;
		font-size: 150%;
	}
}


.login-input {
	input,
	textarea {
		&:disabled {
			//border: 0 !important;
			border-color: transparent !important;
		}
	}
	&-button {
		&-after {
			padding: 0.85em 1em 0.35em;
		}
	}
}

$track-height: 0.5rem;
$thumb-size: 1.4rem;

@mixin track() {
	box-sizing: border-box;
	border: none;
	height: $track-height;
	background: $medium-gray;
	color: $medium-gray;
}

@mixin thumb() {
	box-sizing: border-box;
	border: none;
	width: $thumb-size;
	height: $thumb-size;
	background: $kpmg-medium-blue;
	border-radius: 0;
}

input[type='range'].range-styled {
	&,
	&::-webkit-slider-thumb {
		-webkit-appearance: none
	}
	min-height: $thumb-size;
	background: transparent;
	&::-webkit-slider-runnable-track {
		@include track()
	}
	&::-moz-range-track {
		@include track
	}
	&::-ms-track {
		margin-top: 0.45rem;
		margin-bottom: 0.45rem;
		@include track
	}
	&::-webkit-slider-thumb {
		margin-top: .5*($track-height - $thumb-size);
		@include thumb
	}
	&::-moz-range-thumb {
		@include thumb
	}
	&::-ms-thumb {
		margin-top: 0;
		@include thumb
	}
	&::-ms-tooltip {
		display: none
	}
	&::-ms-fill-lower,
	&::-ms-fill-upper {
		background: $medium-gray;
	}
}


select {
	background-position: right 0 center;
    background-color: $white;
    padding: 0.75rem .75rem;
    border-radius: $prototype-border-radius;
    font-size: 0.875rem;
	height: auto;
}

select:not([multiple]) {
	padding: 0.75rem .75rem;
}

button.notification {
	line-height: 1.5;
}


button.favorite,
button.favorite.clear {
	color: $dark-gray;
	&:focus,
	&:hover {
		color: $dark-gray;
	}
	&.active {
		color: get-color(warning);
		&:focus,
		&:hover {
			color: get-color(warning);
		}
	}
}

button.button {
	&.dropdown-size {
		@include breakpoint(large up) {
			line-height: 1.5;
			font-size: 0.875rem;
			.icon {
				svg {
					vertical-align: unset;
				}
			}
		}
	}
}

.switch-customer-state {
	input:checked ~ .switch-paddle {
		background: $kpmg-green;
	}
	input ~ .switch-paddle {
		background: get-color(alert);
	}
	input:checked + label > .switch-active {
		display: flex !important;
	}
	&.disabled {
		input:checked ~ .switch-paddle {
			//background: transparentize($kpmg-green, 0.30);
			background: transparentize(mix($dark-gray, $kpmg-green, 65%), 0.30);
		}
		input ~ .switch-paddle {
			//background: transparentize(get-color(alert), 0.30);
			background: transparentize(mix($dark-gray, get-color(alert), 65%), 0.30);
			cursor: not-allowed;
		}
	}
}

.button {
	&[disabled],
	&.disabled {
		&.warning {
			opacity: 0.5;
		}
	}
}

button {
	.icon {
		opacity: 1;
		display: inline-block;
		//transition: all 0.25s ease-out;
		max-width: 100px;
		vertical-align: top;
		margin: 0 0.6em;
		&.left {
			margin: 0 0.6em 0 0;
		}
		&.right {
			margin: 0 0 0 0.6em;
		}
		svg {
			vertical-align: top;
		}
	}
	&.button-no-svg-margin {
		.icon {
			margin: 0;
		}
	}
	.loading-holder {
		max-width: 0;
		opacity: 0;
		margin: 0;
		//transition: all 0.25s ease-out;
	}
	&.is-loading {
		.icon {
			max-width: 0;
			opacity: 0;
			margin: 0;
		}
		.loading-holder {
			max-width: 100px;
			opacity: 1;
			width: 1em;
			margin: 0 0.6em 0 0;
			&.nomargin {
				margin: 0;
			}
			&.oneemwidth {
				width: 1em;
				.loading {
					width: 1em;
					height: 1em;
					left: -3px;
				}
			}
		}
	}
}

button,
.button {
	&.text-left {
		text-align: left;
	}
	.text-bold {
		font-weight: bold;
	}
}
.input-with-clear {
	~ .input-clear {
		display: none;
		&.is-button-group {
			padding-top: 0;
			padding-bottom: 0;
			height: 100%;
			font-size: 1rem;
		}
	}
	&.is-active {
		~ .input-clear {
			display: block;
		}
	}
}
