.input-group {
    flex-wrap: nowrap;
}

.button-group {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
}
.button-group::after,
.button-group::before {
    display: table;
    content: " ";
    flex-basis: 0;
    order: 1;
}
.button-group::after {
    clear: both;
}
.button-group .button {
    margin: 0;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    flex: 0 0 auto;
}
.button-group .button:last-child {
    margin-right: 0;
}
.button-group.tiny .button {
    font-size: 0.6rem;
}
.button-group.small .button {
    font-size: 0.75rem;
}
.button-group.medium .button {
    font-size: 1.1rem;
}
.button-group.large .button {
    font-size: 1.25rem;
}
.button-group.larger .button {
    font-size: 1.5rem;
}
.button-group.xlarge .button {
    font-size: 2rem;
}
.button-group.expanded .button {
    flex: 1 1 0px;
}
.button-group.stacked,
.button-group.stacked-for-medium,
.button-group.stacked-for-small {
    flex-wrap: wrap;
}
.button-group.stacked .button,
.button-group.stacked-for-medium .button,
.button-group.stacked-for-small .button {
    flex: 0 0 100%;
}
.button-group.stacked .button:last-child,
.button-group.stacked-for-medium .button:last-child,
.button-group.stacked-for-small .button:last-child {
    margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
        flex: 1 1 0px;
        margin-bottom: 0;
    }
}
@media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
        flex: 1 1 0px;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
        display: block;
    }
    .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0;
    }
}

.switch-input {
    position: absolute;
    margin-bottom: 0;
    opacity: 0;
}

input:checked ~ .switch-paddle {
    background: #1779ba;
}

input:checked + label > .switch-active {
    display: block;
}

input:checked + label > .switch-inactive {
    display: none;
}

label {
    &.checkbox__container,
    &.radio__container {
        &.alert {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.tippy-box {
    .tippy-content {
        padding: 10px;
    }
}
