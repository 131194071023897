@mixin loader-keyframe($name: default, $color: $black) {
	@keyframes loader-animation-#{$name} {

		0%,
		100% {
			box-shadow: 0 -1em 0 0 rgba($color, 1),
				0.7em -0.7em 0 0 rgba($color, .2),
				1em 0 0 0 rgba($color, .2),
				0.7em 0.7em 0 0 rgba($color, .2),
				0 1em 0 0 rgba($color, .2),
				-0.7em 0.7em 0 0 rgba($color, .2),
				-1em 0 0 0 rgba($color, .5),
				-0.7em -0.7em 0 0 rgba($color, .7);
		}

		12.5% {
			box-shadow: 0 -1em 0 0 rgba($color, .7),
				0.7em -0.7em 0 0 rgba($color, 1),
				1em 0 0 0 rgba($color, .2),
				0.7em 0.7em 0 0 rgba($color, .2),
				0 1em 0 0 rgba($color, .2),
				-0.7em 0.7em 0 0 rgba($color, .2),
				-1em 0 0 0 rgba($color, .2),
				-0.7em -0.7em 0 0 rgba($color, .5);
		}

		25% {
			box-shadow: 0 -1em 0 0 rgba($color, .5),
				0.7em -0.7em 0 0 rgba($color, .7),
				1em 0 0 0 rgba($color, 1),
				0.7em 0.7em 0 0 rgba($color, .2),
				0 1em 0 0 rgba($color, .2),
				-0.7em 0.7em 0 0 rgba($color, .2),
				-1em 0 0 0 rgba($color, .2),
				-0.7em -0.7em 0 0 rgba($color, .2);
		}

		37.5% {
			box-shadow: 0 -1em 0 0 rgba($color, .2),
				0.7em -0.7em 0 0 rgba($color, .5),
				1em 0 0 0 rgba($color, .7),
				0.7em 0.7em 0 0 rgba($color, 1),
				0 1em 0 0 rgba($color, .2),
				-0.7em 0.7em 0 0 rgba($color, .2),
				-1em 0 0 0 rgba($color, .2),
				-0.7em -0.7em 0 0 rgba($color, .2);
		}

		50% {
			box-shadow: 0 -1em 0 0 rgba($color, .2),
				0.7em -0.7em 0 0 rgba($color, .2),
				1em 0 0 0 rgba($color, .5),
				0.7em 0.7em 0 0 rgba($color, .7),
				0 1em 0 0 rgba($color, 1),
				-0.7em 0.7em 0 0 rgba($color, .2),
				-1em 0 0 0 rgba($color, .2),
				-0.7em -0.7em 0 0 rgba($color, .2);
		}

		62.5% {
			box-shadow: 0 -1em 0 0 rgba($color, .2),
				0.7em -0.7em 0 0 rgba($color, .2),
				1em 0 0 0 rgba($color, .2),
				0.7em 0.7em 0 0 rgba($color, .5),
				0 1em 0 0 rgba($color, .7),
				-0.7em 0.7em 0 0 rgba($color, 1),
				-1em 0 0 0 rgba($color, .2),
				-0.7em -0.7em 0 0 rgba($color, .2);
		}

		75% {
			box-shadow: 0 -1em 0 0 rgba($color, .2),
				0.7em -0.7em 0 0 rgba($color, .2),
				1em 0 0 0 rgba($color, .2),
				0.7em 0.7em 0 0 rgba($color, .2),
				0 1em 0 0 rgba($color, .5),
				-0.7em 0.7em 0 0 rgba($color, .7),
				-1em 0 0 0 rgba($color, 1),
				-0.7em -0.7em 0 0 rgba($color, .2);
		}

		87.5% {
			box-shadow: 0 -1em 0 0 rgba($color, .2),
				0.7em -0.7em 0 0 rgba($color, .2),
				1em 0 0 0 rgba($color, .2),
				0.7em 0.7em 0 0 rgba($color, .2),
				0 1em 0 0 rgba($color, .2),
				-0.7em 0.7em 0 0 rgba($color, .5),
				-1em 0 0 0 rgba($color, .7),
				-0.7em -0.7em 0 0 rgba($color, 1);
		}
	}
}

@function color-pick-contrast-loader-keyframe($base, $colors: ($white, $black), $tolerance: $global-color-pick-contrast-tolerance) {
	$contrast: color-contrast($base, nth($colors, 1));
	$best: nth($colors, 1);
	$loader: 'loader-keyframe-black';

	@for $i from 2 through length($colors) {
		$current-contrast: color-contrast($base, nth($colors, $i));
		@if ($current-contrast - $contrast > $tolerance) {
			$contrast: color-contrast($base, nth($colors, $i));
			$best: nth($colors, $i);
		}
	}

	@if ($contrast-warnings and $contrast < 3) {
		@warn "Contrast ratio of #{$best} on #{$base} is pretty bad, just #{$contrast}";
	}

	@if($best==$white) {
		$loader: 'loader-animation-white';
	} @else {
		$loader: 'loader-animation-black';
	}
	@return $loader;
}