.external-engagement {

    .filepond--root {
        width: 100%;
    }

    .filepond--drop-label {
        background-color:#FBFAFE;
        background-image: none !important;
        border-radius: 20px;
        border: 1px dashed #C9C8C9;
        min-height: 8.75em !important;
    }

    .filepond--uploaded-file {
        background-color:#FBFAFE;
        border-radius: 5px;
    }
}
