.react-datepicker {
    &-wrapper {
        display: block;
    }
    &__day {
        &--today {
            font-weight: bold;
            border: 1px dashed $gray-3;
            border-radius: 0.3rem;
        }
    }
    &__day,
    &__month-text,
    &__quarter-text,
    &__year-text {
        &--keyboard-selected {
            background-color: $primary;
        }
    }
    &-form-control {
        padding-right: 5rem;
        &.sm {
            padding-right: 4rem;
        }
    }
    &-icons {
        &-container {
            right: 0;
            top: 0;
            bottom: 0;
            width: 5rem;
            &.sm {
                width: 4rem;
            }
        }
    }
    &-icon {
        &-calendar,
        &-clear {
            &-wrapper {
                padding: 8px;
                cursor: pointer;
                transition: color ease-in-out 250ms;
            }
        }
        &-calendar {
            &-wrapper {
                position: absolute;
                right: calc(1rem - 6px);
                top: 0;
                bottom: 0;
                color: $gray-800;
            }
        }
        &-clear {
            &-wrapper {
                color: $gray-2;
                &:hover {
                    color: $gray-800;
                }
                &.sm {
                    font-size: 0.875rem;
                }
            }
        }
    }
    &-indicatorSeparator {
        align-self: stretch;
        margin: auto;
        height: 54%;
        position: absolute;
        background: #9e9e9e;
        top: 0;
        bottom: 0;
        left: calc(2.25rem - 3px);
        width: 1px;
        &.sm {
            left: calc(1.75rem - 3px);
        }
    }
}
