.line-height-1 {
	line-height: 1;
}
.line-height-12 {
	line-height: 1.2;
}

.text {
	&-smallest {
		font-size: 0.625rem !important; // 10 px
	}
	&-smaller {
		font-size: 0.75rem !important; // 12 px
	}
	&-small {
		font-size: 0.875rem !important; // 14 px
	}
	&-normal {
		font-size: 1rem !important;
	}
	&-large {
		font-size: 1.25rem !important;
	}
	&-larger {
		font-size: 1.5rem !important;
	}
}

.text-color {
	@each $key,$val in $foundation-palette{
		&-#{$key} {
			color: get-color($key) !important;
			&-important {
				color: get-color($key) !important;
			}
		}
	}
}

.text-overflow {
	&-ellipsis {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.line-height {
	&-1 {
		line-height: 1;
	}
}

.inverted {
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		color: $white;
	}
	.reveal {
		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6 {
			color: inherit;
		}
	}
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	&.normal {
		font-family: $body-font-family;
		color: $black;
		font-weight: normal;
	}
}

/*.admin {
	&-user {
		&-external {
			h1,
			.h1,
			h2,
			.h2,
			h3,
			.h3,
			h4,
			.h4,
			h5,
			.h5,
			h6,
			.h6 {
				color: $kpmg-light-purple;
			}
		}
	}
}*/

.text {
	&-body-font {
		font-family: $body-font-family !important;
	}
}


.h4 {
	&-medium-down {
		@extend .h4;
		font-family: $body-font-family;
		color: $black;
		@include breakpoint(large up) {
			font-size: 100%;
		}
	}
}




ul.dashed {
	list-style-type: none;
	padding-left: 1em;
	li {
		&:before {
			content: '\2014';
			position: absolute;
			margin-left: -1em;
		}
	}
}


.text-link-style {
	line-height: inherit;
	color: $anchor-color;
	text-decoration: $anchor-text-decoration;
	cursor: pointer;
	&:hover,
	&:focus {
		color: $anchor-color-hover;
		@if $anchor-text-decoration-hover != $anchor-text-decoration {
		text-decoration: $anchor-text-decoration-hover;
		}
	}
}

.humongous {
	font-size: 5rem;
}
