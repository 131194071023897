@keyframes ticket-notify {
	0% {
		transform: scale(1);
		box-shadow: $prototype-box-shadow;
	}

	50% {
		transform: scale(1.1);
		box-shadow: 0 2px 5px 0 rgba($kpmg-green, 0.5), 0 2px 10px 0 rgba($kpmg-green, 0.5);
	}

	100% {
		transform: scale(1);
		box-shadow: $prototype-box-shadow;
	}
}


.ticket-notify {
	animation-name: ticket-notify;
	animation-iteration-count: 2;
	animation-duration: 1s;
}

@keyframes team-notify {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.team-notify {
	animation-name: team-notify;
	animation-iteration-count: 2;
	animation-duration: 1s;
	border: 1px solid rgba($kpmg-green, 0.5);
	box-shadow: 0 1px 5px 0 rgba($kpmg-green, 0.5), 0 1px 10px 0 rgba($kpmg-green, 0.5);
}






@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDown {
	animation-name: fadeInDown;
}


@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		padding-top: 0;
		padding-bottom: 0;
		margin: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.fadeOutUp {
	animation-name: fadeOutUp;
}


.animated {
	animation-duration: 500ms;
	animation-fill-mode: both;
	overflow: hidden;
	@media (print), (prefers-reduced-motion) {
		animation: unset !important;
		transition: none !important;
	}
	&.inifite {
		animation-iteration-count: infinite;
	}
}
