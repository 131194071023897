.nav-main {
  &.navbar {
    &.navbar-top {
      height: 60px;
      z-index: 1021;
      @include media-breakpoint-up(lg) {
        height: 115px;
      }
    }
    padding-bottom: 0;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    .nav-split-dropdown .dropdown-toggle-split {
      margin-right: 0.35625rem;
      margin-left: 0.35625rem;
    }
    .menu-notification {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(50%, 50%);
      @include media-breakpoint-up(lg) {
        transform: translate(-50%, 0%);
      }
      &.bell {
        transform: translate(-40%, -30%);
      }
    }
  }
  .dropdown .dropdown-menu {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03),
      0px 1px 4px rgba(0, 0, 0, 0.05);
  }
  .nav-link,
  .btn.nav-link {
    @include media-breakpoint-up(lg) {
      border-bottom: 0.5rem solid transparent;
    }
    &:hover {
      background-color: $primary-5;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        border-bottom: 0.5rem solid rgba($primary-5, 0.5);
      }
    }
    &.active {
      background-color: $primary-5;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        border-bottom: 0.5rem solid $primary-5;
      }
    }
  }
}

.nav-desktop {
  &.navbar-nav {
    a.nav-link,
    a.nav-link:hover {
      color: theme-color("white");
    }
    > a:first-child {
      padding-left: 0;
      margin-left: 0;
    }
    > .btn-group .btn-link {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .nav-link,
  .btn.nav-link {
    display: block;
    padding: 0.5rem 0 !important;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .nav-split-dropdown .dropdown-toggle-split {
    margin-right: 0.35625rem;
    margin-left: 0.35625rem;
  }
  .menu-notification {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(50%, 50%);
    @include media-breakpoint-up(lg) {
      transform: translate(-50%, 0%);
    }
    &.bell {
      transform: translate(-40%, -30%);
    }
  }
}
