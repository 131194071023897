.show-all {
	@include position(absolute, 0, 0, null, null);
}

.profile-image {
	background-color: $medium-gray;
}

.chat-contact {
	.profile-image {
		@include breakpoint(medium down) {
			height: 2rem;
			width: 2rem;
		}
	}
}

.profile-image-inline-text {
	height: 1.2em;
    line-height: 1em;
    vertical-align: middle;
    margin-top: -.2em;
}

.offline {
	.offline-message {
		display: block;
	}
}

.offline-message {
	display: none;
	/*width: 100%;
	@include breakpoint(large up) {
		width: 25%;
	}*/
}

.chat-unread,
.unread {
	transform: translateY(-25%) translateX(-25%) scale(0.8);
	@include breakpoint(medium up) {
		transform: translateY(-25%) translateX(-25%) scale(0.9);
	}
	@include breakpoint(large up) {
		transform: none;
	}
	background-color: get-color(alert);
	width: 1.5rem;
	height: 1.5rem;
	font-size: 0.875rem;
	&-count {
		margin: auto;
		line-height: 1.5rem;
	}
}