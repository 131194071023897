.tooltip-inner {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.05);
}

.bs-tooltip-bottom {
    .tooltip-inner {
        box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.1),
            0px -1px 4px rgba(0, 0, 0, 0.05);
    }
}

.checklist-tooltip {
    &-task {
        padding: 0.5rem 0;
        border-bottom: 1px solid $gray-2;
        &-last {
            border-bottom: 0;
        }
        &-title {
            padding-bottom: 0.7rem;
            padding-top: 1.5rem;
        }
    }
}