.subnav-customer,
.subnav-generic {
  background-color: $primary-5;
  top: 60px;
  @include media-breakpoint-up(lg) {
    top: 115px;
  }
  .nav-link {
    font-size: 0.85rem;
    font-weight: bold;
    color: rgba($white, 0.75);
    @include media-breakpoint-up(lg) {
      padding: 0.75rem 1rem;
    }
    &.active {
      color: $white;
    }
  }
  .navbar-toggler {
    color: $white;
    font-size: 1rem;
  }
}
