.draggable {
    &.is-dragging {
      transform: scale(1.05);
        .draggable-content,
        &.draggable-content {
            background-color: $primary-1;
            border-color: $primary-3;
            border-style: solid;
            border-radius: $border-radius;
            border-width: 1px;
        }
    }
    .status-icon {
      border: 2px solid;
      transform: translate(40%, -40%);
      z-index: 1;
      &.success {
        border-color: $success-1;
        background-color: $success-1;
      }
      &.warning {
        border-color: $warning-1;
        background-color: $warning-1;
      }
      &.danger {
        border-color: $danger-1;
        background-color: $danger-1;
      }
    }
  }
  