@each $name, $color in $state-colors {
  .bg-#{$name} {
    background-color: map-get($color, normal) !important;
  }
  .bg-#{$name}-hover {
    background-color: map-get($color, normal) !important;
    &:hover {
      background-color: map-get($color, hover) !important;
    }
  }
}
