.nav-tabs {
    &.nav-fill {
        .nav-link {
            text-align: left;
        }
    }
    .nav-link {
        font-size: 1.125rem;
        color: $gray-3;
        //padding: 0.5rem 1rem 0.5rem 0;
        @include transition($transition-base);
        border-color: $gray-2;
        margin-right: 0.625rem;
        &:hover {
            color: $gray-4;
        }
    }
}

@each $state, $stateColors in $statesWithColors {
    .state-#{$state}-block {
        .nav-tabs {
            border-color: map-deep-get($statesWithColors, $state, "border");
        }
        @each $color, $colorValue in $stateColors {
            @if $color == "bgNormal" {
                .nav-tabs {
                    .nav-link {
                        color: map-deep-get(
                            $statesWithColors,
                            $state,
                            "border"
                        );
                        border-color: map-deep-get(
                            $statesWithColors,
                            $state,
                            "border"
                        );
                        &.active,
                        &:hover {
                            color: map-deep-get(
                                $statesWithColors,
                                $state,
                                "color"
                            );
                            border-color: map-deep-get(
                                    $statesWithColors,
                                    $state,
                                    "color"
                                )
                                map-deep-get($statesWithColors, $state, "color")
                                transparent
                                map-deep-get(
                                    $statesWithColors,
                                    $state,
                                    "color"
                                );
                        }
                        &.active {
                            border-bottom-color: map-deep-get(
                                $statesWithColors,
                                $state,
                                $color
                            );
                        }
                    }
                }
            }
        }
    }
}

.modal-body {
    .nav.nav-tabs {
        margin-bottom: 1rem;
    }
}
