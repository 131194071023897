.notification {
  border-bottom: 1px solid $gray-300;
  &:hover {
    cursor: pointer;
    background: theme-color("light");
    border-bottom-color: theme-color("primary");
  }
}

.notification-list {
  height: 600px;
  overflow-y: scroll;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 400px;
  }
  .notification-image {
    max-width: rem-calc(60px);
  }
}
