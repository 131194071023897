.profile-image {
    background-color: theme-color("secondary");
    &-inline-text {
        height: 1.2em;
        &-large {
            height: 2em;
        }
    }
    &-large {
        height: 2em;
    }
    &-xlarge {
        height: 2.5em;
    }
    &-xxlarge {
        height: 3em;
    }
}

.profile-image .cursor {
    &-pointer {
        cursor: pointer;
    }
}

.profile-image {
    &-background {
        background-color: $gray-3;
    }
}

.avatar-upload-wrapper {
    border: 1px dashed;
    background-size: cover;
    canvas {
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
    .loading-holder {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 9999;
        height: 100%;
        left: 0;
        right: 0;
        width: 100%;
        overflow: hidden;
    }
}

.avatar-upload {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
}

.avatar-upload-text {
    display: block;
    transform: translateY(-50%);
    width: 90%;
    margin: 50% auto 0;
}
