.list-block {
    background-color: $gray-1;
    &.state-default-block {
        background-color: $gray-1;
    }
    &.is-draggable {
        border-style: dashed;
        border-width: 2px;
        border-color: $gray-3;
    }
    &.is-dragging {
        border-style: solid;
        border-color: $primary-3;
        background-color: $primary-2;
    }
    .status-icon {
        border: 2px solid;
        transform: translate(40%, -40%);
        z-index: 1;
        &.success {
            border-color: $success-1;
            background-color: $success-1;
        }
        &.warning {
            border-color: $warning-1;
            background-color: $warning-1;
        }
        &.danger {
            border-color: $danger-1;
            background-color: $danger-1;
        }
    }
}
