@import "comments";
@import "ticket-header";

.ticket-header-actions {
  z-index: 990;
  top: 60px;
  @include media-breakpoint-up(lg) {
    top: 115px;
  }
}
