/*.services-terms {
	border: $input-border-focus;
	box-shadow: inset $input-shadow-focus;
	padding: 0.5rem;
	min-height: 500px;
}*/

.service-button {
	.icon {
		transform: rotate(0deg);
		transition: all 0.25s ease-in-out;
		display: inline-block;
		&.spin {
			transform: rotate(180deg);
		}
	}
}

.service-order-view {

}

.sni-group-button-wrapper {
	.sni-group-button-clear {
		display: none;
	}
	&.is-active {
		.sni-group-button-clear {
			display: block;
		}
	}
}

.sni-group-button {
	max-width: 100%;
}
.sni-group-wrapper {
	&.is-search-hit {
		padding-top: 2rem;
	}
}