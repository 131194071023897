.header {
	position: sticky;
	top: 0;
	height: $navbar-height !important;
	width: 100%;
	background: $kpmg-medium-blue;
	color: $white;
	z-index: 100;
	@include breakpoint(large up) {
		height: $navbar-height-large !important;
	}
	.logo {
		width: auto;
		vertical-align: middle;
		height: 32px;
		@include breakpoint(large up) {
			height: 74px;
		}
	}
	a {
		color: $white;
	}
}


.header-notification-mobile,
.chat-notification-mobile {
	svg {
		height: 26px;
	}
	> * {
		pointer-events: none;
	}
}

.submenu-container {
	@include breakpoint(large up) {
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
	}
}
