#new-report-terms-and-conditions{
    text-decoration: underline;
    cursor: pointer;
}

#new-report-terms-and-condition-label{
    color: #8A8A8A;
    a:hover {
        color:#2C2C2C;
    }
}
