/**
 * Fix for sticky header scroll
 */
.off-canvas-wrapper {
	overflow: initial !important;
}

/**
 * Fix for horizontal scroll when off-canvas open
 */
.off-canvas-wrapper {
	&.is-open {
		overflow: hidden !important;
	}
}

/**
 * Fix for content when off canvas is NOT opened
 */
.off-canvas-content.has-transition-push:not(.is-open-left) {
	transform: none !important;
}

.off-canvas-content {
	&.is-open-left {
		cursor: pointer;
	}
}

.off-canvas {
	color: $white;
	background: $kpmg-medium-blue;
	a {
		color: $white;
	}
}

.off-canvas {
	.close-button {
		color: $white;
	}
}
