.table-mytickets {
    .ticket {
        .type {
            &::before {
                content: "";
                width: 0.1875rem;
                height: 1.5rem;
                display: inline-block;
                vertical-align: top;
                margin-right: 0.5rem;
                border-radius: 0.25rem;
                border: 1px solid;
            }
            &.CT {
                &::before {
                    background-color: $primary-4;
                    border-color: $primary-4;
                }
            }
            &.PT {
                &::before {
                    background-color: $warning-3;
                    border-color: $warning-3;
                }
            }
        }
    }
}

.ticket-listing {
    .tbody {
        font-size: 0.875rem;
    }
    .ticket {
        &-id,
        &-attachments,
        &-created,
        &-changed,
        &-assigned,
        &-status {
            flex-grow: 0;
            flex-basis: auto;
        }
    }
    .ticket-id {
        width: 100px;
    }
    .ticket-title {
        min-width: 140px;
    }
    .ticket-attachments {
        width: 10px;
    }
    .ticket-status {
        width: 60px;
    }
    .ticket {
        &-created,
        &-changed,
        &-assigned {
            width: 100px;
        }
    }
}
