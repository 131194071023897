.chat {
	&-bar {
		//@include position(absolute, null, 0, 0, null);
		@include position(fixed, null, 0, 0, null);
		height: rem-calc(40px);
	}
	&-unread {
		transform: translateY(-25%) translateX(-25%) scale(0.8);
		@include breakpoint(medium up) {
			transform: translateY(-25%) translateX(-25%) scale(0.9);
		}
		@include breakpoint(large up) {
			transform: none;
		}
		background-color: get-color(alert);
		width: 1.5rem;
		height: 1.5rem;
		font-size: 0.875rem;
		&-count {
			margin: auto;
			line-height: 1.5rem;
		}
	}
	&-wrapper {
		@include breakpoint(large up) {
			width: rem-calc(275px);
			height: 100%;
			&.chat-contact-list {
				width: rem-calc(250px);
			}
		}
	}
	&-button {
		&-emoji {
			color: $dark-gray !important;
			&:hover {
				color: #ffcc33 !important;
			}
		}
	}
	&-list,
	&-contact {
		@include breakpoint(large up) {
			@include position(absolute, null, 0, 0, 0);
			//border-radius: $prototype-border-radius $prototype-border-radius 0 0;
			height: 100%;
			background: $white;
			&-open {
				height: rem-calc(400px);
				@include position(absolute, null, 0, 0, 0);
			}
		}
	}
	&-contact {
		&-unread {
			background-color: get-color(alert);
			width: 100%;
			height: 0;
			padding-top: 100%;
			//line-height: 3rem;
			@include breakpoint(large up) {
				width: 1rem;
				height: 1rem;
				line-height: 1rem;
				padding-top: 0;
			}
			&-count {
				@include breakpoint(medium down) {
					margin: auto;
					margin-top: 50% !important;
					transform: translateY(-50%);
					line-height: 1;
					top: 0;
					height: 1rem;
				}
			}
		}
		&-close-button {
			line-height: 0.5;
			height: 1rem;
			width: 1rem;
			font-size: 1.5rem;
			z-index: 99;
		}
		&-message {
			border-radius: rem-calc(15px);
			&.sent {
				background-color: $kpmg-light-blue
			}
			&.recieved {
				background-color: $light-gray;
			}
			p:last-child {
				margin: 0;
			}
			&-new,
			&-new:focus {
				box-shadow: none;
				border: 0;
				border-bottom: rem-calc(1px) solid $medium-gray;
			}
		}
		.chat-status {
			height: 0.5rem;
			width: 0.5rem;
			background: $dark-gray;
		}
		&-online {
			&:hover {
				@include breakpoint(large up) {
					cursor: pointer;
				}
			}
			.chat-status {
				background: get-color(success);
			}
		}
		&:hover {
			.chat-list-contact-favorite {
				visibility: visible;
			}
		}
		.chat-list-contact-favorite {
			color: $dark-gray;
			@include breakpoint(large up) {
				font-size: 0.75rem;
				margin-top: 0.25rem;
				visibility: hidden;
			}
			&-selected {
				visibility: visible;
				color: $kpmg-light-purple;
			}
		}
	}
	&-list {
		&-contacts {
			@include breakpoint(large up) {
				height: rem-calc(300px);
			}
		}
		&-contact {
			&:hover {
				.chat-list-contact-favorite {
					visibility: visible;
				}
			}
			.chat-status {
				background: $dark-gray;
				margin-top: 0.15rem;
			}
			&-unread {
				font-weight: bold;
			}
			&-online {
				&:hover {
					cursor: pointer;
				}
				.chat-status {
					background: get-color(success);
				}
			}
			&-chat {
				@include breakpoint(large up) {
					height: rem-calc(280px);
				}
			}
			&-favorite {
				color: $dark-gray;
				@include breakpoint(large up) {
					visibility: hidden;
					display: inline-block;
				}
				&-selected {
					visibility: visible;
					color: $kpmg-light-purple;
				}
			}
		}
	}
	&-status {
		height:0.75em;
		width:0.75em;
		&-online {
			background:$kpmg-green;
		}
		&-bar {
			height: rem-calc(40px);
		}
	}
}

.chat-list.expanded {
	.chat-contact {
		&-name {
			width: 100%;
			transition: background 0.05s ease-out, width 0.05s ease-out;
			background: transparent;
			&:hover {
				z-index: 99;
				background: rgba($white, 0.85);
				text-overflow: initial;
				width: 185px !important;
			}
		}
	}
}

.chat-list {
	&.compressed {
		.icon-compress {
			display: none;
		}
	}
	&.expanded {
		.icon-expand {
			display: none;
		}
	}
}

.chat-list-border-bottom {
	border-bottom: rem-calc(1px) solid $medium-gray;
}
