.signing-table {
    .document {
        width: 40%;
    }

    .company {
        width: 10%;
    }

    .company-name {
        width: 70%;
    }
}

.signing-body {
    > div {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}
