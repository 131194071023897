ul.changelog-list {
	list-style: none;
	li {
		padding-bottom: 0.5rem;
		&:before {
			content: "–";
			padding-right: 0.25rem;
		}
		ul {
			list-style: none;
			li {
				padding-bottom: 0;
			}
		}
	}
}


.cell.changelog-version {
	max-height: rem-calc(900px);
	transition: max-height ease-in-out 0.25s;
	overflow: hidden;
	.changelog-button {
		.icon {
			display: inline-block;
			transform: rotate(0deg) ease-in-out;
			transition-duration: 0.25s;
			transition-timing-function: ease-in-out;
			&.spin {
				transform: rotate(180deg);
			}
		}
	}
	&.collapsed {
		max-height: rem-calc(80px);
		cursor: pointer;
	}
}

.changelog {
	&-feature {
		border-bottom: 1px solid $light-gray;
		cursor: s-resize;
		&:last-child {
			border-bottom: 0;
		}
		&.is-active {
			outline: none;
			border: $input-border-focus;
			box-shadow: $input-shadow-focus;
			transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
			cursor: n-resize;
			.changelog-feature-details {
				display: block;
			}
		}
		.changelog-feature-details {
			display: none;
		}
	}
}