h1 {
  &.page-title {
    font-size: 3.125rem;
    line-height: 1.2;
    letter-spacing: 1px;
    font-family: "KPMG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

h2 {
  font-weight: normal;
}

h3,
h5 {
  font-weight: bold;
}

h4,
h6 {
  font-style: italic;
}
